import config from '../config';
import axios from 'axios';
import { isEmpty } from 'lodash';

const apiUrl = config.apiUrl;

export const fetchShuLastLocation = (tId, fleetId) => {
  return fetch(apiUrl + '/api/shu-last-location', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tId,
      fleetId,
    }),
  }).then(res => res.json());
};

export const fetchPrimaryGpsLocation = (tId, listingId) => {
  return fetch(apiUrl + '/api/v1/gps/get-device-location', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      listingId: listingId,
      transactionId: tId,
    }),
  }).then(res => res.json());
};

export const checkTripCondition = (tId) => {
  return fetch(apiUrl + `/api/sentrilock/${tId}/check-trip-conditions`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  }).then(res => res.json());
};

export const getGpsLocationCoordinates = async (params) => {
  try {
    const { listingId, hasOtoplug, hasPrimaryGps, transactionId } = params;

    let endpoint = '/api/partner/grab/shu-status';

    if (hasPrimaryGps) endpoint = '/api/partner/grab/primaryGps-location';
    else if (hasOtoplug) endpoint = '/api/partner/grab/otoplug-location';

    let body = {
      listingId,
    };

    if (transactionId) body.transactionId = transactionId;

    const res = await axios.post(`${apiUrl}${endpoint}`, body);

    if (res.status === 200) {
      if (!isEmpty(res.data)) return res.data;
    }
  } catch (error) {
    console.error(error, 'fetch-shu-failed', params);
  }
};
