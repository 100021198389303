import { io } from 'socket.io-client';

let socket = null;
// const baseUrl = "https://api-test.drivemate.au";
const baseUrl = process.env.REACT_APP_API_SERVER_URL;

export const initiateSocketConnection = () => {
  socket = io(baseUrl, {
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    console.log('Socket connected:', socket?.id);
  });

  socket.on('disconnect', () => {
    console.log('Socket disconnected');
  });

  socket.on('connect_error', (err) => {
    console.log('Connection Error:', err);
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected after ${attemptNumber} attempts`);
  });
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    console.log('Socket disconnected');
  }
};

export const subscribeToEvent = (eventName, callback) => {
  console.log("subscribeToEvent", subscribeToEvent);
  if (!socket) {
    console.error('Socket is not connected');
    return;
  }

  socket.on(eventName, callback);
};

export const unsubscribeToEvent = (eventName, callback) => {
  if (!socket) {
    console.error('Socket is not connected');
    return;
  }

  socket.off(eventName, callback);
};

export const emitEvent = (eventName, data) => {
  if (!socket) {
    console.error('Socket is not connected');
    return;
  }

  socket.emit(eventName, data);
};

export const getSocket = () => socket;
