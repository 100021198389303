import React from 'react';
import { FormattedMessage } from 'react-intl';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  LINE_ITEM_DISTANCE_CHARGE,
  LINE_ITEM_DISTANCE_FEE,
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
  LINE_ITEM_PROTECTION_FEE,
} from '../../util/types';
import Explanation from './Explaination';
import classNames from "classnames";
import css from './BookingBreakdown.css';
import { get } from 'lodash';

const LineItemForDistanceFee= ({
  transaction,
  isCustomer,
  intl,
  isAddons,
  isTripDetailsPage,
  isExplanationBreakdown,
}) => {

  if (isCustomer) return null;

  console.log('Booking breakdown Transaction >> LINE ITEM FOR DISTANCE FEE');

  console.log('Booking breakdown Transaction >> LINE ITEM FOR DISTANCE FEE > transactions >>', transaction.attributes.lineItems);
  
  const distanceFeeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DISTANCE_FEE && !item.reversal
  );

  console.log('Booking breakdown Transaction >> LINE ITEM FOR DISTANCE FEE > ', distanceFeeLineItem);
  
  const initialPaymentLineItem =
    transaction &&
    transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
    );

    console.log('Distance Fee >> initialPaymentLineItem', initialPaymentLineItem);

  const distanceFee = distanceFeeLineItem.lineTotal;

  const formattedDistance = distanceFee ? formatMoney(intl, distanceFee, 2) : null;

  return formattedDistance ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {`Distance fee`}
      </span>
      <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedDistance}</span>
    </div>
  ) : null;
};

export default LineItemForDistanceFee;
