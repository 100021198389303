import React from 'react';
import { useSelector } from 'react-redux';
import { RiCheckLine } from 'react-icons/ri';
import css from '../index.css';

const ProgressBarComponent = ({ isPickUp }) => {
  // Access the current step from Redux state
  const progressBarState = useSelector(state => state && state.ProgressBarReducer) || {};

  const {
    pickStep,
    pickTotalSteps,
    dropStep,
    dropTotalSteps
  } = progressBarState;


  const { step, totalSteps } = isPickUp
  ? { step: pickStep, totalSteps: pickTotalSteps }
  : { step: dropStep, totalSteps: dropTotalSteps };

  const clampedStep = Math.min(Math.max(step, 1), totalSteps);
  const stepsArray = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <div className={css.container}>
      <div className={css.progressContainer}>
        <div
          className={css.progress}
          style={{ width: `${(clampedStep - 1) / (totalSteps - 1) * 92}%` }}
        />
        {stepsArray.map(i => (
          <div
            key={i}
            className={`${css.stepcircle} ${i < clampedStep ? css.completed : ''} ${i === clampedStep ? css.current : ''}`}
          >
            {i < clampedStep ? <RiCheckLine /> : (i === totalSteps && i === clampedStep ? <RiCheckLine /> : i)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBarComponent;
