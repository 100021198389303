import React, { useState } from "react";
import get from 'lodash/get';

import phoneIcon from "../../assets/newPickupAndDropoff/phone-solid.svg";
import commentIcon from "../../assets/newPickupAndDropoff/comment.png";
import ModalTripInstructions from "./Modals/ModalTripInstructions/ModalTripInstructions";
import NoAvatarIcon from '../Avatar/NoAvatarIcon';


import css from "./StartTripPanel.css";
import config from "../../config";

const getHostPhoneNumber = transaction => {
  if(transaction && transaction.customer &&
    transaction.customer.attributes  &&
    transaction.customer.attributes.profile &&
    transaction.customer.attributes.profile.publicData &&
    transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus === "confirmed" ) {
      return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
    }
  return null;
};



export default function TripInfo({ registrationNumber, fuelType, listing, transaction, isPickUp, intl, mileageToShowText,isDrivelahGo, isLockboxCar, isOlderVersion, isDistanceCharge, distanceKmPrice, excessReductionType, learnMoreForTolls, learnMoreForExcessReduction, learnMoreForDistance }) {
  const fuelRulesConfig = config.custom.typeOfFuel;
  let fuelRecord = fuelRulesConfig.find((fuel) => {
    return fuel.key === fuelType;
  });
  const fuelValue = fuelRecord ? fuelRecord.label : fuelType;

  const carBrand = listing?.attributes?.publicData?.brandName || '';
  const carModel = listing?.attributes?.publicData?.modelName || '';

  const brandModel = `${carBrand} ${carModel}`.trim();

  const provider = listing && listing.author && listing.author.attributes && listing.author.attributes.profile
    ? listing.author.attributes.profile.displayName
    : undefined;

  const keyRules = listing && listing.attributes && listing.attributes.publicData
    ? listing.attributes.publicData.keyRules
    : undefined;

  const keyFeatures = listing && listing.attributes && listing.attributes.publicData
    ? listing.attributes.publicData.keyFeatures
    : undefined;

  const profileImage = listing && listing.author && listing.author.profileImage && listing.author.profileImage.attributes && listing.author.profileImage.attributes.variants && listing.author.profileImage.attributes.variants["square-small"]
    ? listing.author.profileImage.attributes.variants["square-small"].url
    : undefined;

  const phoneNumber = getHostPhoneNumber(transaction);

  const getFormattedBrandName = brandModel => {
    if (!brandModel) return '-';
    return brandModel
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const scrollToChat =() => {
    const section = document.getElementById("trip-send-message-button-id");
    if (!section) return
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const instructionsText = isDrivelahGo
  ? (isPickUp
      ? "The car is equipped with keyless access technology. Once you've completed the start trip process, the car will automatically unlock. You'll find the keys in the glovebox."
      : "Please complete the trip and place the keys in the glovebox. When you're done, kindly lock the car using the app.")
  : isLockboxCar ?
      (isPickUp
        ? "The car keys are in the lockbox located near the driver's window. Once you've completed the start trip process, we'll provide you with the code to unlock the lockbox."
        : "Please complete the trip and securely place the keys in the lockbox. Don't forget to return the lockbox to its original spot."):
      (isPickUp
      ? "Please meet the host to collect the car keys. Coordinate directly with them to arrange the handover."
      : "Kindly meet the host to return the car keys");


  return (
    <div>
      <div className={css.startTripRightWrapper}>
        <div className={css.startTripRightContainer}>

          <div className={css.instructionsWrapper}>
            <h5> { isPickUp ? 'Where to find the keys' : 'Return the key' }</h5>
            <span>
            {instructionsText}
            </span>
          </div>
          <div className={css.instructionsWrapper}>
            <div className={`${css.displayFlex} ${css.justifyContentBetween}`}>
              <div className={css.avatarinfoContainer}>
                <div className={css.avatarWrapperProviderDesktop}>
                  {profileImage ? (<img src={profileImage} className={css.userImage} alt="profile image"/>) :   <NoAvatarIcon />}

                </div>
                <div className={css.userNameContainer}>
                  <h5>Who to contact</h5>
                  <span>{provider}</span>
                </div>
              </div>
              <div className={css.contactWrapper}>
                <div className={`${css.contactContainer} ${css.contactBlueBg}`}>

                  <a
                    className={css.userTelephone}
                    href={`tel:${phoneNumber}`}
                  >
                  <img src={phoneIcon} alt="phone icon" />
                  </a>
                </div>

                <div className={`${css.contactContainer} ${css.contactBlueBg}`}>
                  <a className={css.userChat} onClick={scrollToChat}>
                    <img src={commentIcon} alt="comment icon" />
                  </a>

                </div>
              </div>
            </div>

          </div>
          { isPickUp ?
              <div className={`${css.instructionsWrapper} ${css.instructionLinkBtn}`}>
              <h5>Instructions from the host</h5>
              <span>
                <button onClick={() => setIsModalOpen(true)}>
                  View key instructions for starting trip
                </button>
              </span>
              </div>
            :

              <div className={css.instructionsWrapper}>
                <h5>Fuel</h5>
                {!isOlderVersion ?
                  (<span>You only pay for distance, not fuel. If you have topped up fuel, upload receipt during this drop off process to get a full refund.</span>)
                  :
                  (<span>Make sure you refuel car to the same fuel level at pick up.</span>)
                }
              </div>
          }
        </div>

      </div>
      {isPickUp &&
        <div className={css.startTripRightContainer}>
        <div className={css.instructionsWrapper}>
            <h5>Registration number of the car</h5>
            <span>{registrationNumber}</span>
          </div>
          <div className={css.instructionsWrapper}>
            <h5>Brand/Model</h5>
            <span>{getFormattedBrandName(brandModel)}</span>
          </div>
          <div className={css.instructionsWrapper}>
            <h5>Fuel type</h5>
            <span>{fuelValue}</span>
          </div>
      </div>
      }
      <ModalTripInstructions
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        keyRules= {keyRules}
        brandModel= {brandModel}
        fuelValue = {fuelValue}
        registrationNumber= {registrationNumber}
        listing = {listing}
        intl={intl}
        transaction={transaction}
        mileageToShowText={mileageToShowText}
        isDistanceCharge={isDistanceCharge}
        distanceKmPrice={distanceKmPrice}
        excessReductionType={excessReductionType}
        learnMoreForTolls={learnMoreForTolls}
        learnMoreForExcessReduction={learnMoreForExcessReduction}
        learnMoreForDistance={learnMoreForDistance}
      />
    </div>
  );
}
