import React from 'react';

const UploadIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_2"
        data-name="Layer 2"
        width="32"
        height="31.996"
        viewBox="0 0 32 31.996"
      >
        <g id="Layer_1" data-name="Layer 1" transform="translate(0 0)">
          <path
            id="Path_1904"
            data-name="Path 1904"
            d="M15.986,206.781c-2.963,0-5.928.021-8.89-.005a6.832,6.832,0,0,1-6.178-3.593,6.649,6.649,0,0,1-.893-3.376q-.012-3.072,0-6.147a1.476,1.476,0,0,1,1.047-1.482,1.384,1.384,0,0,1,1.6.6,2.034,2.034,0,0,1,.252.938c.023,1.969.011,3.937.012,5.907a4.072,4.072,0,0,0,4.19,4.223H24.906a4.071,4.071,0,0,0,4.2-4.217v-6.043a1.449,1.449,0,0,1,.983-1.389,1.37,1.37,0,0,1,1.584.475,1.689,1.689,0,0,1,.324.877c.013,2.289.065,4.578-.045,6.867a6.888,6.888,0,0,1-5.654,6.238,9.664,9.664,0,0,1-1.49.121C21.867,206.787,18.927,206.781,15.986,206.781Z"
            transform="translate(-0.018 -174.793)"
            fill="#18a3ad"
          />
          <path
            id="Path_1905"
            data-name="Path 1905"
            d="M75.791,5.019V23.275a1.473,1.473,0,0,1-1.129,1.612,1.422,1.422,0,0,1-1.158-.242,1.458,1.458,0,0,1-.586-1.04c-.012-.159-.009-.32-.009-.481V5.108L72.762,5a1.448,1.448,0,0,1-.181.329q-2.884,2.93-5.775,5.86a1.462,1.462,0,0,1-1.273.512,1.384,1.384,0,0,1-1.205-.97,1.4,1.4,0,0,1,.334-1.522q4.328-4.39,8.662-8.767a1.418,1.418,0,0,1,2.139.082l7.411,7.5c.368.372.738.742,1.1,1.118a1.486,1.486,0,0,1,.052,2.144,1.428,1.428,0,0,1-2.094-.071q-2.906-2.93-5.806-5.877C76.044,5.26,75.953,5.176,75.791,5.019Z"
            transform="translate(-58.35 0.001)"
            fill="#18a3ad"
          />
        </g>
      </svg>
    </div>
  );
};

export default UploadIcon;
