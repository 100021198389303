
import verifyInProgressImg from '../../../assets/newPickupAndDropoff/veirfyInProgress-desktop.png';
import idVerifyImg from '../../../assets/newPickupAndDropoff/idVerify-desktop.png';
import inspectExteriorImg from '../../../assets/newPickupAndDropoff/inspect-exterior-logo.gif';
import Inspecttheinterior from '../../../assets/newPickupAndDropoff/Inspecttheinterior.svg';
import Fuelgaugelevel from '../../../assets/newPickupAndDropoff/Fuelgaugelevel.png';
import Odometerreading from '../../../assets/newPickupAndDropoff/Odometerreading.png';
import verificationFailed from "../../../assets/newPickupAndDropoff/verifyFailed-mobile.png"


export const IdentityVerificationText = {
    verificationInProgress: {
      title: 'Verification in progress',
      message: 'We are verifying your identity. 90% of our checks complete in under 1 minute. You can continue on the next step while we process this in the background.',
      img: verifyInProgressImg,
      buttonText: 'Continue to next step',
      className : 'buttonAlignment'
    },
    identityVerification: {
      title: 'Identity verification',
      message: 'We need to verify you are the rightful guest before you start your trip.',
      img: idVerifyImg,
      buttonText: 'Continue'
    },
    verificationError: {
      title: 'Verification failed',
      message: "Our checks returned that you are not the authorised guest for this trip. If we have made a mistake please give us a call.",
      img: verificationFailed,
      buttonText: 'Call us',
      className : 'buttonAlignment'
    }
};

export const InspectExteriorContentText = (isPickup) => {
  return isPickup
    ? {
        InspectExteriorView: {
          title: 'Inspect the exterior',
          message:
            'Examine and inspect the exterior of the car carefully to ensure you document every damage on the car.',
          img: inspectExteriorImg,
          buttonText: 'Take photos',
        },
        InspectExteriorViewWithPhotos: {
          title: 'Inspect the exterior',
          message: 'Great! This will be added to your trip summary. Minimum 8 photos, one from each side',
          buttonText: 'Continue to next step',
        },
      }
    : {
        InspectExteriorView: {
          title: 'Document exterior status',
          message: 'Take clear photos of exterior of the car to document the return condition.',
          img: inspectExteriorImg,
          buttonText: 'Take photos',
        },
        InspectExteriorViewWithPhotos: {
          title: 'Document exterior status',
          message: 'Great! This will be added to your trip summary. Minimum 8 photos, one from each side',
          buttonText: 'Continue to next step',
        },
        // InspectExteriorViewWithPhotosAndText: {
        //   title: 'Document exterior status',
        //   message: 'Great! This will be added to your trip summary.',
        //   otherText: 'Facing technical issues?',
        //   buttonText: 'Continue to next step',
        // },
      };
};

export const FeedbackContext = {
  FeedbackContent: {
    title: "Cleanliness",
    message: "How clean is the interior of the car. Provide a rating below.",
    buttonText: "Complete pickup"
  }
}

export const ReviewMessageChip = {
  "1": [
    { id: 1, text: "Excessive dirt and dust", isSelected: false },
    { id: 2, text: "Unpleasant odors throughout", isSelected: false },
    { id: 3, text: "Visible stains on seats and floor", isSelected: false },
    { id: 4, text: "Trash or debris left behind", isSelected: false },
    { id: 5, text: "Sticky or grimy surfaces", isSelected: false }
  ],
  "2": [
    { id: 6, text: "Moderate dirt on mats and carpets", isSelected: false },
    { id: 7, text: "Lingering foul smell", isSelected: false },
    { id: 8, text: "Some stains and spills are noticeable", isSelected: false },
    { id: 9, text: "Cluttered interior", isSelected: false },
    { id: 10, text: "Slight stickiness on the surfaces", isSelected: false }
  ],
  "3": [
    { id: 11, text: "Average cleanliness, some areas need attention", isSelected: false },
    { id: 12, text: "Light stains or spots on upholstery", isSelected: false },
    { id: 13, text: "Faint odour that may be improved", isSelected: false },
    { id: 14, text: "General tidiness but not perfect", isSelected: false },
    { id: 15, text: "Surface dust and minor debris", isSelected: false }
  ],
  "4": [
    { id: 16, text: "Clean interior with minimal flaws", isSelected: false },
    { id: 17, text: "Fresh scent, well-aired cabin", isSelected: false },
    { id: 18, text: "Well-maintained seats and carpets", isSelected: false },
    { id: 19, text: "Neat and organized layout", isSelected: false },
    { id: 20, text: "Overall tidy appearance", isSelected: false }
  ],
  "5": [
    { id: 21, text: "Immaculately clean and well-groomed", isSelected: false },
    { id: 22, text: "Inviting fragrance, like a new car smell", isSelected: false },
    { id: 23, text: "Spotless upholstery and flooring", isSelected: false },
    { id: 24, text: "Exceptionally neat and tidy", isSelected: false },
    { id: 25, text: "Pristine condition throughout", isSelected: false }
  ]
};

export const InspectInteriorContentText = {
    InspectExteriorView: {
      title: 'Inspect the interior of the car',
      message: 'Get the keys from the host and unlock the car. You are just a few steps away from embarking on your trip.',
      img: Inspecttheinterior,
      buttonText: 'Continue to interior inspection'
    },

    FuelGaugeLevelViewPhotos: {
      title: 'Fuel gauge level',
      message: 'Record the current level of fuel in the car. Take a clear photo which shows the current level clearly to avoid extra charges levied at trip end.',
      img: Fuelgaugelevel,
      buttonText: 'Take photos'
    },

    FuelGaugeLevelViewPhotosDropoff: {
      title: 'Fuel gauge level',
      message: 'Record the current level of fuel in the car. Take a clear photo which shows the current level clearly.',
      img: Fuelgaugelevel,
      buttonText: 'Take photos'
    },

    FuelGaugeLevelView: {
        title: 'Fuel gauge level',
        message: 'Great! The photo will be added to your trip summary.',
        buttonText: 'Continue to next step'
    }
};


export const OdometerReadingContentText = {
    odometerReadingView: {
        title: 'Odometer reading',
        message: 'Record the current reading of Odometer (total distance the car has travelled). Take a clear photo which shows the current level clearly to avoid extra charges levied at trip end.',
        img: Odometerreading,
        buttonText: 'Take photos'
    },
    odometerReadingViewDropoff: {
      title: 'Odometer reading',
      message: 'Record the current reading of Odometer (total distance the car has travelled). Take a clear photo which shows the current level clearly.',
      img: Odometerreading,
      buttonText: 'Take photos'
    },
    odometerReadingPhotos: {
        title: 'Odometer reading',
        message: 'Great! The photo will be added to your trip summary.',
        buttonText: 'Continue to next step'
    },
}

export const SetToGoContentText = {
  setToGoContent: {
      title: 'Important points to note',
      message: 'All the steps are done and you are good to go. Have a great and safe trip. Some simple guidelines to follow.',
      buttonText: 'All set to go',
      className : 'buttonAlignment'
  }
}

export const EndTripContentText = {
  endtripcontent: {
      title: 'Ready to end the trip',
      message: 'Some things to keep note of before you end the trip.',
      buttonText: 'End Trip',
      className : 'buttonAlignment'
  }
}
