import React, { useState } from 'react';
import css from '../index.css';

const Chip = ({ data = [], onChipSelect }) => {
  const handleChipClick = id => {
    onChipSelect(id);
  };

  return (
    <div className={css.chipWrapper}>
      {data.map(({ id, text, isSelected }) => (
        <div
          key={id}
          className={css.chip}
          onClick={() => handleChipClick(id)}
          style={{
            backgroundColor: isSelected ? '#0aa3ad' : 'white',
            color: isSelected ? 'white' : 'black',
          }}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default Chip;
