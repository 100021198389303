import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_INCONVENIENCE_FEE,
  LINE_ITEM_INCONVENIENCE_PAYOUT,
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_LATE_RETURN_CHARGE,
  LINE_ITEM_LATE_RETURN_FEE,
  LINE_ITEM_LATE_RETURN_PAYOUT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
} from '../../util/types';
import Explanation from './Explaination';
import classNames from "classnames";
import css from './BookingBreakdown.css';

const LineItemForInconvenience = ({
  transaction,
  isCustomer,
  intl,
  isAddons,
  isTripDetailsPage,
  isExplanationBreakdown,
}) => {

  const inconvenienceLineItem = isCustomer ? transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INCONVENIENCE_FEE && !item.reversal
  ) : transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INCONVENIENCE_PAYOUT && !item.reversal
  );

  const inconvenience =inconvenienceLineItem ? inconvenienceLineItem.lineTotal : null;

  const formattedInconvenience = inconvenience ? formatMoney(intl, inconvenience, 2) : null;

  return formattedInconvenience ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {isCustomer ? 'Inconvenience charge' : 'Inconvenience payout'}
      </span>
      <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedInconvenience}</span>
    </div>
  ) : null;
};

export default LineItemForInconvenience;
