import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { generatePresignedUploadUrl, uploadToS3 } from '../../util/fileUpload';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCamera, IconClose, IconSpinner } from '../../components/index';
import css from '../../components/TripPanel/SectionsCarPhotos.css';
import imageCompression from "browser-image-compression";

// Lazy load heic2any library
const loadHeic2Any = () => import('heic2any');

const ACCEPT_TYPE = 'image/jpeg, image/png, image/gif, image/webp, image/bmp, image/svg+xml, image/tiff, image/heif, image/heic';

const UploadSection = forwardRef(({
  intl,
  isCustomer,
  isPickUp,
  currentUser,
  onNoteChange,
  description,
  setIsUploaded,
  setUploading,
  setLoading,
  type,
  note,
  photosData,
  setPhotosData,  
  setActivePhotoSection,
  id,
  setIsExpectedLength,
  setLastImageUrl,
  exteriorPhotoFun
}, ref) => {
  const [uploadFileError, setUploadFileError] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [filesName , setFilesName] = useState()
  const inputElement = useRef(null);

  useImperativeHandle(ref, () => ({
    handleClickUpload() {
      inputElement.current.click();
    }
  }));

  useEffect(() => {
    if (photosData && photosData.length) {
      setUploadedPhotos(photosData);
      setIsUploaded(true);
    }
  }, [])

  useEffect(() => {
    if(uploadedPhotos.length) {
      handleAddPhoto(uploadedPhotos);
      if (setLastImageUrl && uploadedPhotos.length > 0) {
        setLastImageUrl(filesName);
      }
    }
  },[uploadedPhotos])
  
  const handleFileChange = (e) => {
    const input = e.target;
    const selectedFiles = Array.from(input.files).filter(file => file.type.startsWith('image/'));

    if (selectedFiles.length) {
      uploadFilesHandler(selectedFiles.map((file, index) => ({ index, file })));
    } else {
      setUploadFileError(true);
    }
    input.value = '';
  };

  const uploadFilesHandler = async (files) => {
    if (files.length) {
      setIsUploaded(true)
      setLoading(true);
      setUploadFileError(false);
      try {
        // Compress files
        const processedFiles = await Promise.all(
          files.map(async ({ file, index }) => {
            try {
              let processedFile = file;
  
              // Convert HEIF/HEIC to JPEG
              if (file.type === "image/heif" || file.type === "image/heic") {
                // Load heic2any library
                const { default: heic2any } = await loadHeic2Any();
                const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
                processedFile = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpeg"), {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
              }
  
              // Compress the file (converted or original)
              const options = { maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true };
              const compressedBlob = await imageCompression(processedFile, options);
              const compressedFile = new File([compressedBlob], processedFile.name, {
                type: processedFile.type,
                lastModified: Date.now(),
              });
  
              return { file: compressedFile, index };
            } catch (err) {
              console.error(`Error processing file: ${file.name}`, err);
              throw err;
            }
          })
        );

        // Generate pre-signed URLs in bulk
        const urlPromises = processedFiles.map(({ file }) =>
          generatePresignedUploadUrl({
            userId: currentUser.id.uuid,
            fileName: file.name,
            fileType: file.type,
            publicFile: true
          })
        );

        const presignedUrls = await Promise.all(urlPromises);

        // Upload files to S3
        const uploadPromises = processedFiles.map(({ file }, index) => 
          uploadToS3(presignedUrls[index].url, file, file.type)
        );

        const uploadedFileUrls = await Promise.all(uploadPromises);

        // Create new photo objects
        const newPhotos = uploadedFileUrls.map((fileUrl, index) => ({
          fileUrl,
          id: id,
          note,
          type: type,
          isPickUp: true,
          timestamp: new Date().getTime() * (index+1),
          isJustUploaded: true,
        }));

        // Update state with new photos
        setUploadedPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
        setActivePhotoSection(newPhotos);

        setLoading(false);
        if (type === 'exteriorPhotos') exteriorPhotoFun(newPhotos);
      } catch (error) {
        console.error('Upload error:', error);
        setUploadFileError(true);
        setLoading(false);
        setIsUploaded(false);
      }
    }
  };


  const handleAddPhoto = () => {
        const allPhotos = []
        uploadedPhotos.map(i => {
        const data = {
        fileUrl: i.fileUrl,
        note: note,
        timestamp: i.timestamp,
        isCustomer: isCustomer,
        isPickUp: isPickUp,
        [type]: true,
      };
      allPhotos.push(data);
    });
    console.log("isPickUpUpload section", isPickUp)
    setPhotosData(allPhotos)
  }

  const handleRemovePhoto = (timestamp) => {
    // Remove photo from uploadedPhotos
    const filteredUploadedPhotos = uploadedPhotos.filter(photo => photo.timestamp !== timestamp);
    setUploadedPhotos(filteredUploadedPhotos);

    // Remove photo from photosData
    const filteredPhotosData = photosData.filter(photo => photo.timestamp !== timestamp);
    setPhotosData(filteredPhotosData);
  };

  useEffect(() => {
    uploadedPhotos.length ? setIsUploaded(true) : setIsUploaded(false);
    if (setIsExpectedLength) {
      setIsExpectedLength(uploadedPhotos.length);
    }
  
    if (uploadedPhotos.length === 0) {
      setUploadFileError(false);
    }
  }, [uploadedPhotos.length]);

  const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.remarks" />;
  const uploadPlaceholder = intl.formatMessage({ id: 'SectionUploadCarStatus.uploadPlaceholder' });

  return (
    <div>
      <div className={ `${css.pickUpPhotosHolder} ${css.startTripPickUpPhotosHolder}`}>
        <div>
          <div className={css.uploadBox} onClick={() => ref.current.handleClickUpload()}>
          </div>
          <input
            accept={ACCEPT_TYPE}
            className={css.inputField}
            type="file"
            name="uploadPhotoStatus"
            id="uploadPhotoStatus"
            multiple
            onChange={handleFileChange}
            ref={inputElement}
          />
        </div>
        {isCustomer && isPickUp && <div className={css.pickUpReading}>{description}</div>}
        {uploadedPhotos.length > 0 && (
          <div className={classNames(css.photosContainer, css.pickUpPhotosContainer)}>
            {uploadedPhotos.map((photo, index) => (
              <div className={css.photoWrapper} key={index}>
                <div className={css.photoWrapperInner}>
                  <div
                    className={css.removePhotoBtn}
                    onClick={() => handleRemovePhoto(photo.timestamp)}
                  >
                    <IconClose size={'small'} />
                  </div>
                  <img
                    src={photo.fileUrl}
                    className={css.tripPhoto}
                    onClick={() => window.open(photo.fileUrl, '_blank')}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {uploadFileError && (
        <p className={css.uploadError}>
          <FormattedMessage id="SectionUploadCarStatus.uploadError" />
        </p>
      )}
    </div>
  );
});

UploadSection.propTypes = {
  intl: PropTypes.object.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  isPickUp: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  onNoteChange: PropTypes.func,
  description: PropTypes.string,
  setIsUploaded: PropTypes.any
};

export default UploadSection;
