import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl} from '../../util/reactIntl';
import {ensureListing} from '../../util/data';
import isEqual from 'lodash/isEqual';
import css from './EditListingSubscriptionPanel.css';
import {
  createStripeSetupIntent,
  loadData,
  stripeCustomer,
} from '../../ducks/subscriptionPanel.duck.js';
import {isScrollingDisabled, manageDisableScrolling} from '../../ducks/UI.duck';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {deletePaymentMethod, savePaymentMethod} from '../../ducks/paymentMethods.duck';
import {handleCardSetup} from '../../ducks/stripe.duck';
import SubscriptionsForm
  from '../../forms/EditListingSubscriptionForm/SubscriptionsForm/SubscriptionsForm';
import {get} from 'lodash';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_PUBLISHED } from '../../util/types.js';

const EditListingSubscriptionPanel = memo(
  props => {
    const {
      className,
      rootClassName,
      listing,
      updateInProgress,
      submitButtonText,
      onSubmit,
      updateShowDeviceTab,
      ready
    } = props;

    const [addsOn, setAddsOn] = useState(null);
    const classes = classNames(rootClassName || css.root, className);

    const currentListing = ensureListing(listing);
    const {publicData} = currentListing.attributes;
    const live = get(currentListing, "attributes.metadata.live", false)
    const {subscriptionPlan, subscriptionPlanAddOn} = publicData
    const listingState = currentListing.attributes.state;

   
  
    const isEligibleForFreeze = (listingState && (listingState === LISTING_STATE_PENDING_APPROVAL || listingState === LISTING_STATE_PUBLISHED) && (subscriptionPlan && (subscriptionPlan === 'good-mates' || subscriptionPlan === 'best-mates'))) || false;

    console.log("FINASSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS", subscriptionPlan,listingState, isEligibleForFreeze)
    return (
      <div className={`${classes} ${css.subscriptionPanel}`}>
        <div className={css.titleSection}>
          <h1 className={css.title}>Subscription plan</h1>
          <p> Select the ideal subscription plan for your listing.</p>
        </div>
        <div className={css.form}>
          <SubscriptionsForm
            updateInProgress={updateInProgress}
            ready={ready}
            saveActionMsg={submitButtonText}
            initValues={{
              plan: publicData.subscriptionPlan
            }}
            onSubmit={async values => {
              const updatedValues = {
                publicData: {
                  subscriptionPlan: values,
                  subscriptionPlanAddOn: {
                    byoSecondaryGps: addsOn.some(addOn => ['best-mates-secondary', 'good-mates-secondary'].includes(addOn)) || false,
                    byoLockbox: values === "good-mates" && addsOn.includes('good-mates-lockbox') || false,
                  }
                }
              };

              console.log("updatedValues", updatedValues);
              return onSubmit(updatedValues);
            }}
            addsOn={addsOn}
            setAddsOn={setAddsOn}
            showDeviceTab={props}
            isEligibleForFreeze={isEligibleForFreeze}
            updateShowDeviceTab={updateShowDeviceTab}
            subscriptionPlanAddOn={subscriptionPlanAddOn}
            subscriptionPlan={subscriptionPlan}
            live={live}
          />
        </div>
      </div>
    );
  },
  (prev, next) =>
    isEqual(
      prev,
      next
    )
);

const {func, object, string, bool} = PropTypes;

EditListingSubscriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingSubscriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};


const mapStateToProps = state => {
  const {currentUser} = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const {stripeCustomerFetched} = state.subscriptionPanel;

  const {handleCardSetupError} = state.stripe;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
  initData: () => dispatch(loadData())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(EditListingSubscriptionPanel);

