import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_INITIAL_PAYMENT } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemOriginalTotal = memo(props => {
  const { intl, transaction } = props;

  const initialPaymentLineItem =
    transaction &&
    transaction.attributes.lineItems.find(item => item.code === LINE_ITEM_INITIAL_PAYMENT);

  const formattedTotal = formatMoney(intl, initialPaymentLineItem.lineTotal, 2);

  const totalToPositiveAmount = formattedTotal ? formattedTotal.replace('-', '') : '';

  return (
    <div className={css.lineItem}>
      <hr className={css.totalDivider} />

      <span className={css.itemTotalLabel}>Total</span>

      <span className={css.itemTotalValue}>{totalToPositiveAmount}</span>
    </div>
  );
});

export default LineItemOriginalTotal;
