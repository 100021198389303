import React, { useState, useRef } from 'react';
import css from '../index.css';
import StartPickTripCss from "./StartPickTrip.css"
import { SetToGoContentText } from './constant'
import alarmImage from '../../../assets/newPickupAndDropoff/AlarmDark.svg';
import carImage from '../../../assets/newPickupAndDropoff/CarDark.svg';
import fuelImage from '../../../assets/newPickupAndDropoff/FuelDark.svg';
import plusTime from '../../../assets/newPickupAndDropoff/Plus TimeDark.svg';
import ButtonsSection from '../../TripPanel/ButtonsSection';
import { FormattedDate, FormattedMessage } from 'react-intl';
import ProgressBar from "../ProgressBar/ProgressBar";

const StartTripComponent = ({
  isPickUp,
  displayTime,
  dateFormatOptions,
  timeZoneMaybe,
  isOlderVersion,
  shouldShowPickUpSection,
  shouldShowDropOffSection,
  showPickUpSection
}) => {

  const currentTextContent = SetToGoContentText.setToGoContent;

  const handleNextStep = () => {
    shouldShowPickUpSection(false)
    shouldShowDropOffSection(false)
  };

  return (
    <>
      <div className={css.stepContainer}>
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
          </div>
        </div>
        <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <div className={css.iconWrapper}>
              <img src={alarmImage}/>
              <span>Return the car on time</span>
            </div>
            <span className={StartPickTripCss.formatDate}>Your trip ends on<FormattedDate
              value={displayTime} {...dateFormatOptions} {...timeZoneMaybe}/>. Late returns will incur significant penalties.</span>
          </div>
        </div>
        <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <div className={css.iconWrapper}>
              <img src={plusTime}/>
              <span>Need more time?</span>
            </div>
            <span>Extend your trip via your trip page before the current booking ends.</span>
          </div>
        </div>
        <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <div className={css.iconWrapper}>
              <img src={carImage}/>
              <span>Respect the car</span>
            </div>
            <span>Treat it like your own and keep it clean--this is someone's personal car.</span>
          </div>
        </div>
        {!isOlderVersion && <div className={css.allSetInstructions}>
          <div className={css.textWrapper}>
            <div className={css.iconWrapper}>
              <img src={fuelImage}/>
              <span>Fuel policy</span>
            </div>
            <span>Refuel as needed, keep receipts, and upload them at drop-off to get your refund. You don't pay for fuel!</span>
          </div>
        </div>}
      </div>
      <div className={css.footerContainer}>
        <div className={`${css.footerWrapper} ${css.stepContainer}`}>
          <div className={css.footerProgressBar}>
            <ProgressBar isPickUp={isPickUp || showPickUpSection}/>
          </div>
          <button className={css.footerButton} onClick={handleNextStep}>
            {(currentTextContent && currentTextContent.buttonText ? currentTextContent.buttonText : '')}
          </button>
        </div>
      </div>
    </>
  );
};

export default StartTripComponent;
