import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
  LINE_ITEMS,
  propTypes,
} from '../../util/types';
import capitalize from 'lodash/capitalize';
import uniqBy from 'lodash/uniqBy';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;


const LineItemUnknownItemsRefundMaybe = props => {
  const { transaction, intl, isProvider, isCustomer } = props;

  // resolve unknown non-reversal line items
  const items = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && item.reversal
  );

  const initialPaymentRefundLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT && item.reversal
  );
  const processingFeeLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE && !item.reversal
  );
  const customerCommissionLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );
  const regularDaysLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_TRIP_PRICE_REGULAR_DAYS && !item.reversal
  );
  const peakDaysLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS && !item.reversal
  );

  const regularTotal = regularDaysLineItem && regularDaysLineItem.lineTotal.amount || 0;
  const peakTotal = peakDaysLineItem && peakDaysLineItem.lineTotal.amount || 0;

  // const modifiedTotal = new Money(-(processingFeeLineItem.lineTotal.amount + customerCommissionLineItem.lineTotal.amount + regularTotal + peakTotal), 'AUD');

  if (isCustomer && initialPaymentRefundLineItem) {
    items.unshift(
      {
        code: "line-item/modified-trip-price",
        // unitPrice: modifiedTotal,
        // lineTotal: modifiedTotal,
        reversal: true
      }
    )
  }

  const noDisplayLineItems= [
    initialPaymentRefundLineItem && LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
    initialPaymentRefundLineItem && LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
    initialPaymentRefundLineItem && LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
    initialPaymentRefundLineItem && LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
    LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
    isProvider && LINE_ITEM_INITIAL_PAYMENT
  ];

  const uniqItems = uniqBy(items, i => i.code);
  return uniqItems.length > 0 ? (
    <React.Fragment>
      {uniqItems.map((item, i) => {
        const label = humanizeLineItemCode(item.code);
        const formattedTotal = formatMoney(intl, item.lineTotal, 1);

        if (noDisplayLineItems.includes(item.code)) return null;

        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{capitalize(`Refund ${label}`)}</span>

            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsRefundMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsRefundMaybe;
