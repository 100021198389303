
import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
  LINE_ITEMS,
  propTypes,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
  LINE_ITEM_UNITS,
} from '../../util/types';
import Explanation from './Explaination';
import css from './BookingBreakdown.css';


const LineItemForDistance = memo(
  props => {
    const { transaction, intl, isTripDetailsPage, isEditTripPage } = props;

    const lineItemsForPayments = [
      LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
      LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
      LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
      LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
    ]
    const lineItemsForRefunds = [
      LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
      LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
      LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
      LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
    ]

    const distanceChargingPayments = transaction.attributes.lineItems.filter(
      item => lineItemsForPayments.includes(item.code) && !item.reversal
    );

    const distanceChargingRefunds = transaction.attributes.lineItems.filter(
      item => lineItemsForRefunds.includes(item.code) && !item.reversal
    );

    const totalArray = transaction.attributes.lineItems.filter(
      item => lineItemsForRefunds.includes(item.code) || lineItemsForPayments.includes(item.code) && !item.reversal
    );

    const regularItem = totalArray.find(item => item.code === LINE_ITEM_TRIP_PRICE_REGULAR_DAYS && !item.reversal);

    const peakItem = totalArray.find(item => item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS && !item.reversal);

    const regularHourlyItem = totalArray.find(item => item.code === LINE_ITEM_TRIP_PRICE_REGULAR_HOURS && !item.reversal);

    const peakHourlyItem = totalArray.find(item => item.code === LINE_ITEM_TRIP_PRICE_PEAK_HOURS && !item.reversal);

    const totalPriceLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_UNITS && !item.reversal
    );

    const totalPriceLineItemRefund = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_UNITS && item.reversal
    );

    const totalPrice = totalPriceLineItem ? totalPriceLineItem.lineTotal : null;

    const totalPriceRefund = totalPriceLineItemRefund ? totalPriceLineItemRefund.lineTotal : null;

    const formattedTotal = totalPrice ? formatMoney(intl, totalPrice, 2) : null;

    const formattedTotalRefund = totalPriceRefund ? formatMoney(intl, totalPriceRefund, 2) : null;

    let explanationText;

    if (regularItem && peakItem) {
      explanationText = `${formatMoney(intl, regularItem.unitPrice, 1)} (Regular price) X ${regularItem.quantity} ${regularItem.quantity > 1 ? "days" : "day"} + ${formatMoney(intl, peakItem.unitPrice, 1)} (Peak price) X ${peakItem.quantity} ${peakItem.quantity > 1 ? "days" : "day"}`
    } else if (regularItem) {
      explanationText = `${formatMoney(intl, regularItem.unitPrice, 1)} (Regular price) X ${regularItem.quantity} ${regularItem.quantity > 1 ? "days" : "day"}`
    } else if (peakItem) {
      explanationText = `${formatMoney(intl, peakItem.unitPrice, 1)} (Peak price) X ${peakItem.quantity} ${peakItem.quantity > 1 ? "days" : "day"}`
    } else if (regularHourlyItem && peakHourlyItem) {
      explanationText = `${formatMoney(intl, regularHourlyItem.unitPrice, 1)} (Regular hourly price) X ${regularHourlyItem.quantity} ${regularHourlyItem.quantity > 1 ? "hours" : "hour"} + ${formatMoney(intl, peakHourlyItem.unitPrice, 1)} (Peak hourly price) X ${peakHourlyItem.quantity} ${peakHourlyItem.quantity > 1 ? "hours" : "hour"}`
    } else if (regularHourlyItem) {
      explanationText = `${formatMoney(intl, regularHourlyItem.unitPrice, 1)} (Regular hourly price) X ${regularHourlyItem.quantity} ${regularHourlyItem.quantity > 1 ? "hours" : "hour"}`
    } else if (peakHourlyItem) {
      explanationText = `${formatMoney(intl, peakHourlyItem.unitPrice, 1)} (Peak hourly price) X ${peakHourlyItem.quantity} ${peakHourlyItem.quantity > 1 ? "hours" : "hour"}`
    }
    
    console.log('Line items for distance >>', {distanceChargingPayments, distanceChargingRefunds, totalPrice, totalPriceRefund, totalArray, regularItem, peakItem});

    return (
      <>
      {distanceChargingPayments.length > 0 && (
        <div className={css.lineItem}>
        <span className={css.itemLabel}>
          Duration charge
          <Explanation text= {explanationText} />
        </span>
        <span className={css.itemValue} style={isEditTripPage ? {fontSize: '15px'} : {}}>{formattedTotal}</span>
      </div>
      )}

      {distanceChargingRefunds.length > 0 && (
        <div className={css.lineItem}>
        <span className={css.itemLabel}>
          Duration charge
          <Explanation text= {explanationText} />
        </span>
        <span className={css.itemValue}>{formattedTotalRefund}</span>
      </div>
      )}
      </>
    );
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemForDistance.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemForDistance;
