import React from 'react';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_INITIAL_PAYMENT } from '../../util/types';

import css from './ModificationCharges.css';

const { Money } = sdkTypes;

const ModificationCharges = ({ transaction, onOpenDetails, intl }) => {
  if (!transaction) return null;

  const initialPaymentLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT
  );

  const payinTotal = transaction.attributes.payinTotal;

  const totalPrice = payinTotal.amount - Math.abs(initialPaymentLineItem.lineTotal.amount);

  const formattedTotalPrice = formatMoney(intl, new Money(totalPrice, payinTotal.currency), 2);

  return (
    <div className={css.modificationChargesRoot}>
      <span className={css.head}>Modification charges</span>
      <div className={css.lineItemsContainer}>
        <span className={css.lineItemName}>Extra duration charges</span>
        <span className={css.lineItemValue}>{formattedTotalPrice}</span>
      </div>

      <div className={css.plus} onClick={onOpenDetails}>
        Show Details
      </div>
    </div>
  );
};

export default ModificationCharges;
