import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import {
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
} from '../../util/transaction';

import css from './TripPanel.css';

const VALID_SHOW_KEY_TRANSITIONS = [
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
  TRANSITION_REQUEST_DROP_OFF,
  ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
];

const isShowKeySectionVisible = (lastTransition, isDrivelahGo) => {
  if (isDrivelahGo && lastTransition) {
    return VALID_SHOW_KEY_TRANSITIONS.includes(lastTransition);
  }
  return false;
};

const InfoSection = ({
                       listingRegistrationNumber,
                       fuelType,
                       selectedOptions,
                       isMobileLayout,
                       showAddress,
                       listing,
                       lastTransition,
                       isDrivelahGo,
                       mileageToShow,
                       isDistanceCharge,
                       distanceKmPrice,
                       learnMoreForDistance,
                       excessReductionType,
                       learnMoreForExcessReduction,
                       learnMoreForTolls
                     }) => {

  const rules = listing.attributes.publicData?.rules || false;
  const keyRules = listing.attributes.publicData?.keyRules || false;
  const carBrand = listing.attributes.publicData.brandName || '';
  const carModel = listing.attributes.publicData.modelName || '';
  const carGuide = listing.attributes.publicData.carGuide || '';
  const parkingInstructions = listing.attributes.publicData.parkingInstructions || '';
  const pickupDropoffInstructions = listing.attributes.publicData.pickupDropoffInstructions || '';


  const [showFullText, setShowFullText] = useState({
    parkingInstructions: false,
    pickupDropoffInstructions: false,
    carGuide: false,
  });

  console.log('listing.attributes.publicData', listing.attributes.publicData)

  const fuelRulesConfig = config.custom.typeOfFuel;
  const fuelRecord = fuelRulesConfig.find((fuel) => fuel.key === fuelType);
  const fuelValue = fuelRecord ? fuelRecord.label : fuelType;

  const showKeySection = isShowKeySectionVisible(lastTransition, isDrivelahGo);

  const formatTextWithLinksAndLineBreaks = text => {
    if (!text) return '-';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} style={{ color: '#0aa3ad' }} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return (
        <React.Fragment key={index}>
          {part.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    });
  };

  const toggleReadMore = section => {
    setShowFullText(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const renderWithReadMore = (text, section) => {
    const isExpanded = showFullText[section];
    const maxLength = 230;
    const maxLines = 3;

    if (!text) return '-';

    // Split text into lines based on '\n'
    const lines = text.split('\n');
    const lineCount = lines.length;

    // Determine if "Read More" is needed based on length or line count
    const needsReadMore = text.length > maxLength || lineCount > maxLines;

    // Decide which text to display (truncated or full)
    let displayedText = isExpanded
      ? text
      : (text.length > maxLength ? text.slice(0, maxLength) : lines.slice(0, maxLines).join('\n'));

    // Add ellipsis only if the text length exceeds maxLength
    if (!isExpanded && text.length > maxLength) {
      displayedText += '...';
    }

    return (
      <>
        {formatTextWithLinksAndLineBreaks(displayedText)}
        {needsReadMore && (
          <button
            className={css.readMoreButton}
            onClick={() => toggleReadMore(section)}
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        )}
      </>
    );
  };

  return (
    <div className={css.infoSectionContainer}>
      {isDistanceCharge && distanceKmPrice && (<div className={css.keyInformationSection}>
        <div>Key information for your trip</div>
        <div>
          <p><span>Distance</span><span>Distance charged @{distanceKmPrice}/km <a onClick={learnMoreForDistance}>Learn more</a></span></p>
          {excessReductionType && (<p><span>Protection</span><span>{`${excessReductionType} protection`} <a onClick={learnMoreForExcessReduction}>Learn more</a></span></p>)}
          <p><span>Tolls</span><span>Due later <a onClick={learnMoreForTolls}>Learn more</a></span></p>
        </div>
      </div>)}

    {/* Card 1 */}
    {showAddress && (
    <div className={css.infoCardContainer}>
      <h4 className={css.infoTitle}>
        <FormattedMessage id="About the car"/>
      </h4>
      <div className={css.bodyTextContainer}>
        {lastTransition === TRANSITION_ENQUIRE ? (
              <>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.registration" />
                  {listing.attributes.publicData.license_plate_number}
                </p>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.fuel" />
                  {fuelValue}
                </p>
              </>
            ) : (
              <>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.about"/>
                  {`${carBrand.charAt(0).toUpperCase() + carBrand.slice(1)} ${
                    carModel.charAt(0).toUpperCase() + carModel.slice(1)
                  }`}
                </p>
                <p className={css.infotext}>
                  <FormattedMessage id="TransactionPanel.fuel"/>
                  {fuelValue}
                </p>
                <p className={css.infotext}>
                  <FormattedMessage id="Registration number: "/>
                  {listing.attributes.publicData.license_plate_number}
                </p>
                {mileageToShow && (
                  <p className={css.infotext}>
                    <FormattedMessage id="TransactionPanel.mileage"/>
                    {mileageToShow}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {/* Card 2 */}
      {keyRules && keyRules.length > 0 && (
        <div className={css.infoCardContainer}>
          <h4 className={css.infoTitle}>
            <FormattedMessage id="Key rules" />
          </h4>
          <div className={css.bodyTextContainer}>
            {keyRules.map((rule, index) => (
              <div className={css.infotext} key={rule}>
                <span className={css.index}>{index + 1}.&nbsp;</span>
                {rule}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Card 2.1 */}
      {rules && (
        <div className={css.infoCardContainer}>
          <h4 className={css.infoTitle}>
            <FormattedMessage id="Instructions" />
          </h4>
          <div className={css.bodyTextContainer}>
            <p className={css.infotext}>{renderWithReadMore(rules, 'rules')}</p>
          </div>
        </div>
      )}

      {/* Card 3 */}
      {parkingInstructions && (
        <div className={css.infoCardContainer}>
          <h4 className={css.infoTitle}>
            <FormattedMessage id="Parking instructions" />
          </h4>
          <div className={css.bodyTextContainer}>
            <p className={css.infotext}>{renderWithReadMore(parkingInstructions, 'parkingInstructions')}</p>
          </div>
        </div>
      )}

      {/* Card 4 */}
      {pickupDropoffInstructions && (
        <div className={css.infoCardContainer}>
          <h4 className={css.infoTitle}>
            <FormattedMessage id="Pickup & return instructions" />
          </h4>
          <div className={css.bodyTextContainer}>
            <p className={css.infotext}>{renderWithReadMore(pickupDropoffInstructions, 'pickupDropoffInstructions')}</p>
          </div>
        </div>
      )}

      {/* Card 5 */}
      {carGuide && (
        <div className={css.infoCardContainer}>
          <h4 className={css.infoTitle}>
            <FormattedMessage id="Car guide" />
          </h4>
          <div className={css.bodyTextContainer}>
            <p className={css.infotext}>{renderWithReadMore(carGuide, 'carGuide')}</p>
          </div>
        </div>
      )}

      {/* Card 6 */}
      {showKeySection && (
        <div className={css.infoCardContainer}>
          <h4 className={css.infoTitle}>
            <FormattedMessage id="TransactionPanel.keyLocationTitle" />
          </h4>
          <div className={css.bodyTextContainer}>
            <ul className={`${css.infotext} ${css.keyLocationList}`}>
              <li key="dlGo">
                <FormattedMessage id="TransactionPanel.keyLocationDescriptionForDlGo" />
              </li>
              <li key="nonDlGo">
                <FormattedMessage id="TransactionPanel.keyLocationDescriptionForNonDlGo" />
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoSection;
