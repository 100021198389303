import React, { useState } from 'react';
import css from './PickUpByAdmin.css';
import { getLockboxCode, unlockShuCar } from '../../util/notification';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const PickUpByAdmin = ({ lockbox, transaction, onLockboxDataChange }) => {
  const isLockBox = lockbox === 'true' ? true : lockbox === 'false' ? false : lockbox;
  const apiData = {
    user_id: transaction.customer.attributes.profile.publicData.shuUserId,
    booking_id: transaction.id.uuid,
    listingId: transaction.listing.id.uuid,
    fleet_id: transaction.listing.attributes.publicData.license_plate_number,
  };
  const [lockboxCode, setLockboxCode] = useState([]);
  const [expireTime, setExpireTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [tripStarted, setTripStarted] = useState(false); // Tracks if the trip start command has been successfully sent

  const handleGenerateCode = async (isForce = false) => {
    setLoading(true);
    setError('');
    try {
      const getCode = await getLockboxCode(apiData.listingId, isForce);
      if (getCode) {
        const codeArray = getCode.code.split('');
        const formattedExpiry = formatDate(getCode.dateValid);

        setLockboxCode(codeArray);
        setExpireTime(formattedExpiry);
        onLockboxDataChange(getCode.code, formattedExpiry);
      }
    } catch (error) {
      console.error("Error generating lockbox code:", error);
      setError('Failed to generate the code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // const handleGenerateCode = async (isForce = false) => {
  //   setLoading(true);
  //   setError('');
  //   try {
  //     // Simulating an API response with a lockbox code and a valid date
  //     const getCode = { code: '342424', dateValid: new Date().toISOString() }; // Mock data resembling expected API response
  //     console.log('came here'); // Debugging log
  //
  //     if (getCode) {
  //       setLockboxCode(getCode.code.split('')); // Assuming the code should be an array of characters for display
  //       setExpireTime(formatDate(getCode.dateValid)); // Formatting the mock valid date
  //     }
  //   } catch (error) {
  //     console.error("Error generating lockbox code:", error); // Error handling
  //     setError('Failed to generate the code. Please try again.');
  //   } finally {
  //     setLoading(false); // Ensuring to reset the loading state
  //   }
  // };

  const handleUnlockCar = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await unlockShuCar(apiData);
      if (response && response.success) {
        setTripStarted(true);  // Change state to indicate that trip has started
        alert('Trip started successfully!');
      } else {
        setError('Failed to start trip. Please try again.');
      }
    } catch (error) {
      console.error("Error starting trip:", error);
      setError('Error occurred while starting the trip.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={css.PickUpByAdmin}>
      <div className={css.pickUpByAdminTitle}>Give access to the guest</div>
      <div className={css.pickUpByAdminContent}>
        {isLockBox ? (
          <>
            <p>This car has a lockbox attached. You will need to provide code to the user.</p>
            {lockboxCode.length > 0 ? (
              <div className={css.lockboxCodeDisplay}>
                <div className={css.unlockCodeWrapper}>
                  {lockboxCode.map((number, index) => (
                    <span key={index}
                          className={(lockboxCode.length > 8) ? css.unlockcodeNo : (lockboxCode.length > 6 ? css.unlockcode : '')}>{number}</span>
                  ))}
                </div>
                <div className={css.expireTimeContent}>
                  <div>Code valid till {expireTime}</div>
                  <div className={css.updateButton} onClick={() => handleGenerateCode(true)}>Update
                    and get a new code
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.pickUpByAdminButton}>
                <button
                  className={css.pickUpByAdminButtonContent}
                  onClick={() => handleGenerateCode(true)}
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Generate code'}
                </button>
              </div>
            )}
          </>
        ) : (
          <p>This is a Drive lah Go car, start the trip by pressing the button below to give access
            of the car to the user. Do this only once you have been provided exterior images.</p>
        )}
        {error && <p className={css.error}>{error}</p>}
      </div>
      {!isLockBox && (
        <div className={css.pickUpByAdminButton}>
          <button
            className={css.pickUpByAdminButtonContent}
            onClick={handleUnlockCar}
            disabled={loading}
          >
            {loading ? 'Loading...' : (tripStarted ? 'Send Start Trip Again' : 'Start Drive lah Go Trip')}
          </button>
        </div>
      )}
    </div>
  );
};

export default PickUpByAdmin;
