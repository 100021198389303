import React, { useEffect, useState } from 'react';
import StarIcon from './Star';
import css from '../index.css';

const ReviewStar = ({ currentRating, setCurrentRating }) => {
  const [rating, setRating] = useState(0);
  const rates = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (currentRating){
      handleClick(currentRating);
    }
  }, [currentRating]);

  const handleClick = (rate) => {
    setRating(rate);
    setCurrentRating(rate);
  };

  return (
    <div className={css.ratingstarWrapper}>
      {rates.map(rate => (
        // <div className={css.starIconsvg}>
          <StarIcon
          key={`star-${rate}`}
          isFilled={rating >= rate}
          onClick={() => handleClick(rate)}
        />
        // </div>
      ))}
    </div>
  );
};

export default ReviewStar;
