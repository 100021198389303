import React from "react";
import css from "./StartTripPanel.css";
import Verification from '../../assets/newPickupAndDropoff/CompleteVerification.svg';
import play from '../../assets/newPickupAndDropoff/PLAY.svg';
import { get } from "lodash";
import Slider from 'react-slick';

export default function TripInstructionsSlider (userData) {
  const isReturning = get(userData, 'currentUser.attributes.profile.metadata.intercomUserStat.success_booking_as_guest', 0);

  console.log("isReturning", isReturning)

  const sliderConfig = {
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: 0,
    dots: true,
    autoplay: true,
    arrows: false,
    draggable: false,
    fade: true,
    autoplaySpeed: 5000
  };

  let bannerContent = [
    {
      id: 1,
      slideTitle: 'Mandatory for insurance to apply',
      slideDescription: 'This step is mandatory or else insurance wont apply.',
      icon: Verification,
    },
    {
      id: 2,
      slideTitle: 'First trip? Watch a quick video',
      slideDescription: 'Learn how you can secure your trip!',
      icon: play,
    },
  ];

  // Filter out the second slide if the user is returning
  if (isReturning) {
    bannerContent = bannerContent.filter(slide => slide.id !== 2);
  }

  return (
    <div className={`${css.tripSliderContainer}`}>
      <Slider {...sliderConfig}>
        {bannerContent &&
          bannerContent.map(l => (
            <div key={l.id} className={css.firstTripWrapper}>
              <div className={css.firstTripSlideWrapper}>
                <div className={`${css.firstTripContentWrapper}`}>
                  <img src={l.icon} />
                  <div className={css.firstTripContent}>
                    <h6>{l.slideTitle}</h6>
                    <span>{l.slideDescription}</span>
                  </div>
                </div>
                {l.id === 1 ? (
                  <div></div>
                ) : (
                  <div className={css.firstTripContentLink}>
                    <a href="">Watch Now</a>
                  </div>
                )}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}
