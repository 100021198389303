import React from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { formatMoney } from '../../../util/currency';
import css from '../BookingBreakdown.css';
import Explanation from '../Explaination';
import config from '../../../config';
import { types as sdkTypes } from '../../../util/sdkLoader';

const { Money } = sdkTypes;

const LineItemLongTermTripFees = ({ transaction, isCustomer, intl }) => {
  if (!isCustomer) {
    return null;
  }

  let totalTripFees = get(transaction, 'attributes.protectedData.commissionObj.total', 0);

  if (!totalTripFees) {
    return null;
  }

  totalTripFees = new Money(totalTripFees, config.currency);
  totalTripFees = totalTripFees ? formatMoney(intl, totalTripFees) : null;

  return totalTripFees ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.commission" />
        <Explanation text="Drive mate fee to operate the platform" />
      </span>
      <span className={css.itemValue}>{totalTripFees}</span>
    </div>
  ) : null;
};

export default LineItemLongTermTripFees;
