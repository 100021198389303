import React from "react";
import css from './TripPanel.css';
import { Button, Modal } from "../../components";

const DropOffCheckList = ({ onClose, cancelButtonId, isOpen, onConfirm, lockBoxCode }) => {
  const [isAllChecked, setIsAllChecked] = React.useState(false);

  const contentArray = [
    `Key has been placed in the lockbox. Your lockbox code is <b>${lockBoxCode}</b>.`,
    'Collected all of your stuff from the car.',
    'Doors are locked and windows closed.',
    'Car is parked at the correct location.',
  ];

  const handleCheckboxChange = () => {
    // Update the state to check if all checkboxes are checked
    const allChecked = contentArray.every((_, index) => {
      const checkbox = document.getElementById(`check-${index}`);
      return checkbox && checkbox.checked;
    });
    setIsAllChecked(allChecked);
  };

  return (
    <Modal
      onClose={onClose}
      containerClassName={css.dropOffModal}
      cancelButtonId={cancelButtonId}
      isOpen={isOpen}
    >
      <div className={css.confirmTripWrapper}>
        <h1>Confirm end trip</h1>
        <p>Please check the following boxes and then move on to complete the end of the trip.</p>
        <div className={css.completeTripWrapper}>
          {contentArray.map((item, index) => (
            <div key={index}>
              <input type="checkbox" id={`check-${index}`} onChange={handleCheckboxChange} />
              <label htmlFor={`check-${index}`} dangerouslySetInnerHTML={{ __html: item }} />
            </div>
          ))}
        </div>
        {console.log("Fire ....")}
        <Button
          className={css.endTripButton}
          onClick={onConfirm}
          disabled={!isAllChecked}
        >
          End trip
        </Button>
      </div>
    </Modal>
  );
};

export default DropOffCheckList;
