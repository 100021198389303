import React from 'react';
import { FormattedMessage } from 'react-intl';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  LINE_ITEM_DISTANCE_CHARGE,
  LINE_ITEM_DISTANCE_FEE,
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
  LINE_ITEM_PROTECTION_FEE,
} from '../../util/types';
import Explanation from './Explaination';
import classNames from "classnames";
import css from './BookingBreakdown.css';
import { get } from 'lodash';

const LineItemForDistanceCharges = ({
  transaction,
  isCustomer,
  intl,
  isAddons,
  isTripDetailsPage,
  isExplanationBreakdown,
}) => {

  console.log('Booking breakdown Transaction >> LINE ITEM FOR DISTANCE CHARGES');

  console.log('Booking breakdown Transaction >> LINE ITEM FOR DISTANCE CHARGES > transactions >>', transaction.attributes.lineItems);
  
  const distanceChargeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_DISTANCE_CHARGE && !item.reversal
  );

  console.log('Booking breakdown Transaction >> LINE ITEM FOR DISTANCE CHARGES > ', distanceChargeLineItem);
  
  const initialPaymentLineItem =
    transaction &&
    transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
    );

    console.log('Distance Charges >> initialPaymentLineItem', initialPaymentLineItem);

  const distanceCharge = distanceChargeLineItem.lineTotal;

  const formattedDistance = distanceCharge ? formatMoney(intl, distanceCharge, 2) : null;

  const unitPrice = distanceChargeLineItem ? convertMoneyToNumber(distanceChargeLineItem.unitPrice) : null;

  const distanceTravelled = distanceChargeLineItem ? distanceChargeLineItem.quantity.d[0] : null;

  return formattedDistance ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        {`Distance charge (${unitPrice} x ${distanceTravelled} km)`}
      </span>
      <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedDistance}</span>
    </div>
  ) : null;
};

export default LineItemForDistanceCharges;
