
import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import { types as sdkTypes } from '../../util/sdkLoader';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
  LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
  LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
  LINE_ITEMS,
  propTypes,
  LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
  LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { get } from 'lodash';
import moment from 'moment-timezone';
import Explanation from './Explaination';
import css from './BookingBreakdown.css';


const LineItemsOld = memo(
  props => {
    const { transaction, intl, isTripDetailsPage } = props;

    const lineItemsForPayments = [
      LINE_ITEM_TRIP_PRICE_REGULAR_DAYS,
      LINE_ITEM_TRIP_PRICE_REGULAR_HOURS,
      LINE_ITEM_TRIP_PRICE_PEAK_DAYS,
      LINE_ITEM_TRIP_PRICE_PEAK_HOURS,
    ]
    const lineItemsForRefunds = [
      LINE_ITEM_TRIP_PRICE_REGULAR_DAYS_REFUND,
      LINE_ITEM_TRIP_PRICE_REGULAR_HOURS_REFUND,
      LINE_ITEM_TRIP_PRICE_PEAK_DAYS_REFUND,
      LINE_ITEM_TRIP_PRICE_PEAK_HOURS_REFUND,
    ]

    console.log('Line items for old >>', transaction.attributes.lineItems);

    const totalArray = transaction.attributes.lineItems.filter(
      item => lineItemsForRefunds.includes(item.code) || lineItemsForPayments.includes(item.code) && !item.reversal
    );

    console.log('Line items for old > total array >', totalArray);

    const ubStart = get(
      transaction,
      'transaction.attributes.protectedData.afterUpdateDates.start',
      null
    );
    const ubEnd = get(
      transaction,
      'transaction.attributes.protectedData.afterUpdateDates.end',
      null
    );

    const diffInHours = ubStart && ubEnd && moment(ubEnd).diff(moment(ubStart), 'hours');

    const { hourlyBooking } = transaction.attributes.protectedData || {};

    const unitMaybe = hourlyBooking && diffInHours <= 6 ? 'hours' : 'days';

    return (
      <>
        {totalArray.map((item, i) => {
          const label = humanizeLineItemCode(item.code);
          console.log("Trip Price text", label);
          const formattedTotal = formatMoney(intl, item.lineTotal, 2);
          const hasQuantity = !!item.quantity;
          const unitQuantity = hasQuantity
            ? item.quantity instanceof Decimal
              ? item.quantity.toString()
              : item.quantity
            : 0;
          const explainationMaybe =
            item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS ? (
              <Explanation text="Peak day pricing is for select high demand days set by the host." />
            ) : null;
          console.log("XXXXXXX", unitMaybe, formatMoney(intl, item.unitPrice, 2), unitQuantity, item);
          return (
            <div key={`${i}-item.code`} className={css.lineItem}>
              <span className={css.itemLabel}>
                {label}
                {explainationMaybe}{' '}
                {hasQuantity ? (
                  <FormattedMessage
                    id={`LineItemUnknownItemsMaybe.${unitMaybe}.quantity`}
                    values={{
                      unitPrice: formatMoney(intl, item.unitPrice, 2),
                      quantity: unitQuantity,
                    }}
                  />
                ) : null}
              </span>

              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          );
        })}
      </>
    );
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemsOld.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemsOld;
