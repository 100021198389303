import React, { Component } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, intlShape } from '../../util/reactIntl';
import setFieldTouched from '../../util/setFiledTouched';
import { compose } from 'redux';
import { FieldSelect, Form } from '../../components';

import css from './ModalCancel.css';

class SelectFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
    };
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={
          this.state.initialValues ? this.state.initialValues : this.props.initialValues
        }
        mutators={{ ...arrayMutators, setFieldTouched }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            intl,
            reasons,
            onChange,
            className,
            name,
            handleSubmit,
            pristine,
            form,
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.rootForm, className);
          const reasonLabel = intl.formatMessage({id: 'ModalCancel.reasonLabel'});
          const reasonPlaceholder = intl.formatMessage({id: 'ModalCancel.reasonPlaceholder'});

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <FormSpy
                onChange={formState => {
                  onChange(formState.values);
                  this.setState({
                    initialValues: formState.values,
                  });
                }}
              />

              <label className={css.selectLabel}>{reasonLabel}</label>
              <div className={css.selectContainer}>
                <FieldSelect
                  className={css.reasonSelect}
                  name="reasonSelected"
                  id="reasonSelected"
                  // label={reasonLabel}
                >
                  <option disabled value="">
                    {reasonPlaceholder}
                  </option>
                  {reasons.map(c => (
                    <option key={c.key} value={c.key}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SelectFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

SelectFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  ready: bool,
  reasons: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

const SelectForm = SelectFormComponent;

export default compose(injectIntl)(SelectForm);
