import React, { memo } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar, InlineTextButton } from '../../components';

import css from './ListingPage.css';
import { ensureListing } from '../../util/data';
import isEqual from 'lodash/isEqual';
import { get } from 'lodash';
import { formatMoney } from '../../util/currency';

const SectionHeading = memo(
  props => {
    const {
      priceTitle,
      formattedPrice,
      richTitle,
      category,
      hostLink,
      showContactUser,
      onContactUser,
      currentListing,
      isInstantBookingListing,
      shouldShowPricePerMonth = false,
      isLongTerm,
      isDisinfected,
      listing,
      intl,
    } = props;
    console.log("Listing,,,,", listing);

    const distanceKm = get(listing, "attributes.publicData.pricing.distanceKm", "");
    const listingPrice = get(listing, 'attributes.price', '');
    const hourlyPrice = get(listing, 'attributes.publicData.pricing.hourlyRegularPrice', '');
    const dailyPrice = get(listing, 'attributes.publicData.pricing.distanceKm', '');

    // const perDayPrice = formatMoney(intl, listingPrice, 1);
    // const perHourPrice = formatMoney(intl, hourlyPrice, 1);
    // const perDayPrice = formatMoney(intl, listingPrice, 1);

    console.log("Listing page prices >>>", { listingPrice, hourlyPrice, dailyPrice, listing, formattedPrice })
    const { reviews = {} } = currentListing.attributes.metadata;

    const unitTranslationKey = shouldShowPricePerMonth || isLongTerm
      ? 'ListingPage.perMonth'
      : distanceKm ? 'ListingPage.day'
        : 'ListingPage.perDay';

    const averageReview = (averageReviews, doneTrips) => {
      if (!averageReviews || averageReviews === 'N/A') {
        if (!doneTrips) {
          return null;
        }
        return (
          <div className={css.averageContainer}>
            <p>
              <strong>Trips:</strong> {doneTrips}
            </p>
          </div>
        );
      }
      return (
        <div className={css.averageContainer}>
          <div>
            <IconReviewStar rootClassName={css.star} />
            {averageReviews}
          </div>
          <p className={css.doneTrips}>
            | <strong>Trips:</strong> {doneTrips}
          </p>
        </div>
      );
    };
    const { author } = ensureListing(currentListing);

    const instantBookingLabel =
      isInstantBookingListing && !shouldShowPricePerMonth && !isLongTerm ? (
        <div className={css.instantBooking}>
          <FormattedMessage id={'ListingPage.instantBookingLabel'} />
        </div>
      ) : null;

    const isDisinfectedLabel =
      isDisinfected ? (
        <div class={css.wrapperLabelDisinfected}>
          <div class={css.numberDays}>
            <div className={css.number}> 60 </div>
            <div className={css.day}> days</div>
          </div>
          <div class={css.textLabel}>
            <span> Disinfected </span>
          </div>
        </div>
      ) : null;

    return (
      <div className={css.sectionHeading}>
        <div className={css.desktopPriceContainer}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
            <div className={css.perUnit}>
              /day
            </div>
          </div>
          {/* {hourlyPrice.amount && (
            <>
              or
              <div className={css.desktopPriceValue} title={priceTitle}>
                ${(hourlyPrice.amount / 100)}
                <div className={css.perUnit}>
                  /hour
                </div>
              </div>
            </>
          )} */}
          {distanceKm && (
            <>
              +
              <div className={css.desktopPriceValue} style={{fontWeight:'500', fontSize: '30px'}} title={priceTitle}>
                ${distanceKm}
                <div className={css.perUnit}>
                  /km
                </div>
              </div>
            </>
          )}
        </div>
        <div className={css.heading}>
          <div className={css.labelGroup}>
            {instantBookingLabel}
            {isDisinfectedLabel}
          </div>
          <h1 className={css.title}>{richTitle}</h1>
          <div className={css.author}>
            {category}
            <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} />
            {showContactUser ? (
              <span className={css.contactWrapper}>
                <span className={css.separator}>•</span>
                <InlineTextButton rootClassName={css.contactLink} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
              </span>
            ) : null}
            {author && author.id
              ? averageReview(
                reviews.averageRating,
                currentListing.attributes.publicData.numberTripDone
              )
              : null}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => {
    return isEqual(prev, next);
  }
);

export default SectionHeading;
