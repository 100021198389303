import React from 'react';

const StarIcon = ({ isFilled, onClick }) => {
  const fillColor = isFilled ? '#0aa3ad' : 'none';
  const strokeColor = '#0aa3ad';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="56px"
      width="56px"
      version="1.1"
      id="Capa_1"
      viewBox="-0.5 0 50 50"
      xmlSpace="preserve"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        style={{ fill: fillColor, stroke: strokeColor, strokeWidth: 1 }}
        d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956C22.602,0.567,25.338,0.567,26.285,2.486z"
      />
    </svg>
  );
};

export default StarIcon;
