// Set state to local storage
export const saveStateToLocalStorage = (id, state) => {
  try {
    localStorage.setItem(id, JSON.stringify(state));
  } catch (error) {
    console.error('Error saving state to localStorage', error);
  }
};

// Get state from local storage
export const getStateFromLocalStorage = (id, defaultState) => {
  try {
    const storedState = localStorage.getItem(id);
    return storedState ? JSON.parse(storedState) : defaultState;
  } catch (error) {
    console.error('Error retrieving state from localStorage', error);
    return defaultState;
  }
};

export const removeStateFromLocalStorage = (id) => {
  try {
    localStorage.removeItem(id);
  } catch (error) {
    console.error('Error removing state from localStorage', error);
  }
};
