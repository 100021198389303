import config from '../config';

const apiUrl = config.apiUrl;


export const uploadFiles = (data) => {
    return fetch(apiUrl + "/api/upload", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        //   'Content-Type': 'application/json'
        },
        body: data
      });
};

export const generatePresignedUploadUrl = async ({ userId, fileName, fileType, publicFile }) => {
  try {
    const response = await fetch(
      `${apiUrl}/api/upload/generate-presigned-url`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          fileName,
          fileType,
          publicFile,
        }),
      }
    );
    if (!response.ok) {
      console.error('Failed to generate presigned upload URL:', response);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error generating presigned upload URL:', error);
  }
};

export const uploadToS3 = async (url, file, fileType) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': fileType,
      },
      body: file,
    });

    if (!response.ok) {
      console.error('Failed to upload to S3:', response);
    }

    return url.split('?')[0]; // Return the S3 object URL without the query string
  } catch (error) {
    console.error('Error uploading to S3:', error);
  }
};

const objToQueryString = (obj) => {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
    }
    return keyValuePairs.join('&');
  }

