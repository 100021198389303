import React from 'react';

import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';

import css from './TripPanel.css';
import { flatten } from 'lodash';

const DropOffSection = props => {
  const {
    shouldShowDropOffSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    isLimitedUser,
    removePhoto,
    isDropOff,
    pickUpOdometer,
    isAdminAction
  } = props;

  // For Node.js version 11.0.0 and higer
  //
  // const filterTripPhotos = allTripPhotos.flat().filter(i => !i.isPickUp);
  // const filterTripPhotos = allTripPhotos
  //   .reduce((acc, val) => acc.concat(val), [])
  //   .filter(i => !i.isPickUp);


  const flatTripPhotos =  [
    ...allTripPhotos,
  ];

  const isShowUpload = (isLimitedUser && isPickUp && isDropOff) || (!isDropOff && !isPickUp)

  const filterTripPhotos = flatten(flatTripPhotos).filter(i => !i.isPickUp);

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={shouldShowDropOffSection}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isLimitedUser ? 'Dropoff as Admin' : isCustomer ? 'Send drop-off request' : 'Confirm drop-off request'}
        </h2>
      </div>
      {photoSectionList.map(i => {
        return (
          <SectionCarPhotos
            id={i.id}
            key={i.id}
            setActivePhotoSection={setActivePhotoSection}
            // onClick={e => setActivePhotoSection(e, i.id)}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer && !isLimitedUser}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={false}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            inboxLabel={i.inboxLabel}
            inboxPlaceholder={i.inboxPlaceholder}
            onNoteChange={onNoteChange}
            removePhoto={removePhoto}
            isShowUpload={isShowUpload}
            pickUpOdometer={pickUpOdometer}
            isAdminAction={isAdminAction}
          />
        );
      })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionListCustomer.map(i => {
          return (
            <SectionCarPhotosOnlyView
              key={i.id}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={isPickUp}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          );
        })}
    </div>
  );
};

export default DropOffSection;
