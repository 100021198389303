import { bool } from 'prop-types';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_IS_EXCESS_REDUCTION,
  propTypes
} from '../../util/types';
import Explanation from './Explaination';

import classNames from "classnames";
import css from './BookingBreakdownNew.css';

const LineItemNewExcessReductionAddOnMaybe = props => {
  const { transaction, isCustomer, intl, isExplanationBreakdown, isTripDetailsPage } = props;

  if (!transaction) return null;

  let excessReductionLineItem = null;

  if (isCustomer) {
    excessReductionLineItem = transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_IS_EXCESS_REDUCTION && !item.reversal
    );
  }

  const initialPaymentLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
  );

  if (isCustomer && initialPaymentLineItem && !isTripDetailsPage && !isExplanationBreakdown) {
    return null;
  }

  if (!excessReductionLineItem) {
    return null;
  }

  const commission = excessReductionLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 2) : null;

  return formattedCommission ? (
    <div className={css.addOnLineItem}>
      <div className={css.totalLabel}>
        <FormattedMessage id="BookingBreakdown.excessReduction" />
        <Explanation text="Reduced maximum liability of $1500" />
      </div>
      <div className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedCommission}</div>
    </div>
  ) : null;
};

LineItemNewExcessReductionAddOnMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemNewExcessReductionAddOnMaybe;
