import React, { useState, useEffect } from 'react';
import styles from './SubscriptionAddOns.css';
import PropTypes from 'prop-types';

const SubscriptionAddOns = ({ subscriptionAddsOnData, setSubscriptionAddsOn, selectedAddOn, selectedPlan, isEligibleForFreeze = false, setShowChangeError }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initialSelectedOptions, setInitialSelectedOptions] = useState([]);

  // Effect to handle default selection based on selectedAddOn
  useEffect(() => {
    if (selectedAddOn) {
      let newSelectedOptions = [];

      // Assign default add-ons based on selectedPlan and selectedAddOn
      // if (selectedAddOn.byoSecondaryGps && selectedPlan === "just-mates") {
      //   newSelectedOptions = ['just-mates-secondary'];
      // }

      if (selectedAddOn.byoSecondaryGps && selectedPlan === "good-mates") {
        newSelectedOptions = ['good-mates-secondary'];
      }

      if (selectedAddOn.byoSecondaryGps && selectedPlan === "best-mates") {
        newSelectedOptions = ['best-mates-secondary'];
      }

      if (selectedAddOn.byoLockbox && selectedPlan === "good-mates") {
        newSelectedOptions.push('good-mates-lockbox');
      }

      // Remove duplicates and set state for initial and current selections
      newSelectedOptions = Array.from(new Set(newSelectedOptions));
      setSelectedOptions(newSelectedOptions);
      setInitialSelectedOptions(newSelectedOptions); // Save initial selection
      setSubscriptionAddsOn(newSelectedOptions); // Update props when new options are set
    }
  }, [selectedAddOn, selectedPlan]);

  // Log selectedOptions to track updates and pass the updated selection to parent
  useEffect(() => {
    setSubscriptionAddsOn(selectedOptions);
  }, [selectedOptions, setSubscriptionAddsOn]);

  // Handle selection and deselection of add-ons
  const handleOptionClick = (optionId, isDisable) => {
    // Show error if the option is disabled
    if (isDisable || (isEligibleForFreeze && initialSelectedOptions.includes(optionId))) {
      setShowChangeError(true);
      return;
    }

    setShowChangeError(false);
    setSelectedOptions((prevSelectedOptions) => {
      const isSelected = prevSelectedOptions.includes(optionId);

      // Prevent deselecting if the option was initially selected
      if (isSelected && isEligibleForFreeze && initialSelectedOptions.includes(optionId)) {
        return prevSelectedOptions;
      }

      // Update selected options based on whether the option is currently selected
      const newSelectedOptions = isSelected
        ? prevSelectedOptions.filter(id => id !== optionId) // Deselect option if not initially selected
        : [...prevSelectedOptions, optionId]; // Select option

      return newSelectedOptions;
    });
  };

  return (
    <div className={`${styles.subscriptionAddons}`}>
      <h3>Select add-ons for your subscription</h3>
      <div className={styles.addonsOptions}>
        {subscriptionAddsOnData &&
          subscriptionAddsOnData.map(({ id, badge, isDisable, content }) => (
            <div
              className={`${styles.addonOption} 
                          ${selectedOptions.includes(id) ? styles.selected : ''} 
                          ${isEligibleForFreeze && initialSelectedOptions.includes(id) ? styles.disabled : ''}`}
              onClick={() => handleOptionClick(id, isDisable)}
              key={id}
            >
              <span>{content}</span>
              <div className={styles.radioCircle}>
                {selectedOptions.includes(id) && <div className={styles.radioSelected} />}
              </div>
              {badge && (
                <div className={styles.badgeContainer}>
                  <p className={styles.badgeContent}>{badge}</p>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

SubscriptionAddOns.propTypes = {
  subscriptionAddsOnData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      badge: PropTypes.string, // Badge content type string
      isDisable: PropTypes.bool,
    })
  ).isRequired,
  setSubscriptionAddsOn: PropTypes.func.isRequired,
  selectedAddOn: PropTypes.object,
  selectedPlan: PropTypes.string.isRequired,
  isEligibleForFreeze: PropTypes.bool,
  setShowChangeError: PropTypes.func.isRequired, // Added PropType for the setShowChangeError function
};

export default SubscriptionAddOns;
