import get from 'lodash/get';
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { obfuscatedCoordinates } from '../../util/maps';
import { types as sdkTypes } from '../../util/sdkLoader';
import StartTripMap from '../Map/StartTripMap';
import css from './StartTripPanel.css';
import NeedUserPermission from './NeedUserPermission';

const { LatLng } = sdkTypes;

const StartTripListingMap = ({ listing, className, showAddress, geolocation, exactAddress, dynamicMarkerIconURL, listingForMap, isCustomer, getDistanceFromPickUp, distanceFromPickUp, userLocation, isPickUp}) => {

  const [isStatic, setStatic] = useState(true);
  const [locationPermission, setLocationPermission] = useState(
    distanceFromPickUp && distanceFromPickUp.locationPermission
  );


  useEffect(() => {
    if ( listingForMap && listingForMap.attributes) {
      const cleanup = getDistanceFromPickUp(geolocation);
      // return () => {
      //   cleanup();
      // };
    }
  }, [listingForMap, geolocation]);

  if (!geolocation) {
    return null;
  }

  const address = get(listing, 'attributes.publicData.location.address', '');

  const modifiedAddress = exactAddress.address || address
  // geolocation = get(listing, 'attributes.geolocation', { lat: 0, lng: 0 });
  const { lat = 0, lng = 0 } = geolocation || {};
  const cacheKey = listing.id ? `${listing.id.uuid}_${lat}_${lng}` : null;
  const hideAddress = !showAddress;

  const mapProps = hideAddress
    ? {
        obfuscatedCenter: obfuscatedCoordinates({ lat, lng }, cacheKey),
        hideAddress,
      }
    : {
        address: modifiedAddress,
        center: new LatLng(lat, lng),
        hideAddress,
      };

  const isUserLocationAvailable = userLocation && userLocation.lat !== null && userLocation.lng !== null;

  const  locationPermissionAction = locationPermission === 'denied' || locationPermission === undefined || locationPermission == "prompt"

  console.log("locationPermission========", locationPermission)
  console.log("isUserLocationAvailable========", isUserLocationAvailable)
  const map = isUserLocationAvailable ? (
    <StartTripMap
      {...mapProps}
      useStaticMap={false}
      hideAddress={hideAddress}
      dynamicLocation={userLocation}
      dynamicMarkerIconURL={dynamicMarkerIconURL}
      distanceFromPickUp={distanceFromPickUp}
      isCustomer={isCustomer}
      isPickUp = {isPickUp}
    />
  ) : (
    <div>Loading...</div>
  );

  return (
    <div>
      { locationPermissionAction && !isUserLocationAvailable ? <NeedUserPermission listingForMap={listingForMap} getDistanceFromPickUp={getDistanceFromPickUp} setLocationPermission={setLocationPermission} isPickUp={isPickUp}/>: <div>
      { isStatic ? (
        <button
          className={classNames(css.map, className)}
          onClick={() => {
            setStatic(false);
          }}
        >
          {map}
        </button>
      ) : (
        <div className={classNames(css.map, className)}>{map}</div>
      )}
    </div>}
    </div>

  );
};

export default StartTripListingMap;
