import React from 'react';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ExtraDurationCharges.css';

const { Money } = sdkTypes;

const ExtraDurationCharges = ({ transaction, intl }) => {
  if (!transaction) return null;

  const initialPaymentLineItem =
    transaction &&
    transaction.attributes.lineItems.find(
      item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
    );

  const modifyTxTotal = transaction.attributes.lineItems.reduce((acc, curr) => {
    const noIcludedLineItems = [
      LINE_ITEM_UNITS,
      LINE_ITEM_INITIAL_PAYMENT,
      LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
      LINE_ITEM_PROVIDER_COMMISSION,
    ];

    if (noIcludedLineItems.indexOf(curr.code) === -1) {
      acc += curr.lineTotal.amount;
    }

    return acc;
  }, 0);

  const payinTotal = transaction.attributes.payinTotal;

  const totalPrice = payinTotal.amount - Math.abs(initialPaymentLineItem.lineTotal.amount);

  const formattedTotalPrice = formatMoney(intl, new Money(totalPrice, payinTotal.currency), 2);

  const modifiedTotal = new Money(modifyTxTotal, 'AUD');

  const modifiedFormattedTotal = formatMoney(intl, modifiedTotal, 2);

  const initialFormattedTotal = formatMoney(intl, initialPaymentLineItem.lineTotal, 2);

  const initialTotalToPositiveAmount = initialFormattedTotal
    ? initialFormattedTotal.replace('-', '')
    : '';

  return (
    <div className={css.extraDurationChargesRoot}>
      <div>
        <div>
          <div className={css.extraDurationTitle}>Extra duration charges</div>
          <div className={css.extraDurationText}>
            Modified price - Original paid ie {modifiedFormattedTotal} -{' '}
            {initialTotalToPositiveAmount}
          </div>
        </div>
      </div>
      <div className={css.extraDurationValue}>{formattedTotalPrice}</div>
    </div>
  );
};

export default ExtraDurationCharges;
