import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdentityVerificationText } from './constant';
import { nextPickStep, resetSteps, setVerificationStatus, enableProgressBar, setPickUpStep } from '../ProgressBar/ProgressBar.duck';
import ProgressBar from '../ProgressBar/ProgressBar';
import dynamic from 'next/dynamic';
import css from '../index.css';
import axios from 'axios';
import { get } from 'lodash';
import { ModalHelp } from '../../../components';
import {CANCEL_BOOKING_BUTTON_ID} from "../../../util/gtm/gtmConstants"

import {
  disconnectSocket,
  emitEvent,
  initiateSocketConnection,
  subscribeToEvent,
} from '../../../util/socket';


let initializeOnfido = null

const DynamicHeader = dynamic(() => import('./dynamicOnfido').then(comp => {
  initializeOnfido = comp;
}), {
})

const IdentityVerificationComponent = ({ isPickUp, isDropUp, currentUser, transaction, isVerification = false, onTransit, setVerificationInprogress}) => {
  const [showInProgress, setShowInProgress] = useState(false);
  const [onfidoInitToken, setOnfidoInitToken] = useState(null);
  const [verificationError, setVerificationError] = useState(false);
  const { verificationStatus, isProgressBarEnabled } = useSelector(state => state.ProgressBarReducer);
  const modalReference = useRef(null);
  const dispatch = useDispatch();
  const [isSocketOn, setIsSocketOn] = useState(false)
  const [onfidoUserIdNew, setOnfidoUserIdNew] = useState("")
  const [showCancelHelpModal, setShowCancelHelpModal] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const transactionData = { transaction };
  const requireVerificationOnTrip =
    currentUser &&
    currentUser.attributes &&
    currentUser.attributes.profile &&
    currentUser.attributes.profile.publicData &&
    currentUser.attributes.profile.publicData.requireVerificationOnTrip;

    const onFidoCheckId =
    transaction &&
    transaction.attributes &&
    transaction.attributes.metadata &&
    transaction.attributes.metadata.s;

  const hasNextStep = showInProgress || verificationError || (isVerification && isProgressBarEnabled);


  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Update based on your breakpoint
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCallButtonClick = () => {
    if (isDesktop) {
      setShowCancelHelpModal(true);
    } else {
      window.location.href = 'tel:+6531389153';
    }
  };

  const handleNextStep = () => {
    dispatch(nextPickStep());
    dispatch(enableProgressBar(true));
  };

  const initSocket = () => {
    initiateSocketConnection()
    setIsSocketOn(true)
    setTimeout(() => {
      emitEvent('joinRoom', currentUser.id.uuid);
      subscribeToEvent('message', (message) => {
        console.log("subscribeToEvent:::Msg", message)
        const status = message && message.split(':').pop().trim() || ''
        console.log("status", status)
        dispatch(setVerificationStatus(status));
      })

    }, 0);
  }

  useEffect(() => {
    if(!isSocketOn) {
      initSocket();
    }
    if(verificationStatus == "clear") {
      
      dispatch(setPickUpStep(2));
      // dispatch(nextPickStep());
    }
  }, [verificationStatus]);


  // useEffect(() => {
  //   return () => {
  //       setIsSocketOn(false)
  //       disconnectSocket()  // Clean up listener
  //   };
  // }, []);


  const fetchOnfidoCheckStatus = async (onfidoUserId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/onfidoCheckPickUpDropOffFlow/${onfidoUserId}`)
      return response;
    } catch (err) {
      throw err;
    }
  }

  const fetchUserVerificationStatus = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/onfidoApplicant/${userId}`);
      return response;
    } catch (err) {
      throw err;
    }
  }
  
  const _createOnfidoCheck = async (onfidoUserId) => {
    try {

      let onFidoCheckId;

      dispatch(setVerificationStatus("pending"));
      setShowInProgress(true);
      dispatch(enableProgressBar(false));

      console.log("onfidoUserId", onfidoUserId);
      const response = await fetchOnfidoCheckStatus(onfidoUserId)
      if (response.data.status === 'in_progress') {
        onFidoCheckId = response.data.id;
      }
      const transactionId = get(transactionData, "transaction.id.uuid")
      const txParams = {
        transactionId: transactionId,
        onFidoCheckId: response.data.id
      }
      await updateMetaFields(txParams)
      console.log("Onfido check created", response);
      // Here you will update the transaction data also
    } catch (error) {
      console.error("onfido error", error);
      // dispatch(setVerificationStatus("reject"));
    }
  };

  const handleInitiateVerification = async () => {

    const userId = get(currentUser, 'id.uuid');
    console.log("userId in handleInitiateVerification", userId);
    try {
      const response = await fetchUserVerificationStatus(userId);
      const token = response && response.data && response.data.data && response.data.data.token;
      const OnfidoUserID = response && response.data && response.data.OnfidoUserID
      setOnfidoUserIdNew(response && response.data && response.data.OnfidoUserID)
      if (token) {
        setOnfidoInitToken(token);
        console.log("onfidoUserId in token", onfidoUserIdNew);
        initializeOnfido.initializeOnfido(token, currentUser, modalReference, _createOnfidoCheck, transactionData, OnfidoUserID, onTransit);
      }
    } catch (error) {
      console.log("Error in onfido", error);
    }
  };



  const updateMetaFields = async (data) => {
    try {
    const response = axios.post(process.env.REACT_APP_API_SERVER_URL + '/api/update-transaction-metadata-with-onfido',
      data,
    );
    return response;
  } catch (err) {
    throw err;
  }
  }

  const isRequiredFaceVerification = () => requireVerificationOnTrip || requireVerificationOnTrip === undefined;
  const isFaceVerificationInitiated = () => Boolean(onFidoCheckId);

  const currentTextContent = verificationStatus === "consider"
    ? IdentityVerificationText.verificationError
    : showInProgress || isVerification
      ? IdentityVerificationText.verificationInProgress
      : IdentityVerificationText.identityVerification;

  return (
    <>
      <div className={css.stepContainer}>
        {isRequiredFaceVerification() && !isFaceVerificationInitiated() && <DynamicHeader />}
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
            <p>{currentTextContent.message}</p>
          </div>
          <div className={css.imgWrapper}>
            <img src={currentTextContent.img} alt={currentTextContent.title} />
          </div>
        </div>
      </div>
      <div ref={modalReference} style={{ width: 200 }} />
      <div className={css.footerContainer}>
        <div className={`${css.footerWrapper} ${css.stepContainer} ${css[currentTextContent.className]}`}>
          {!showInProgress && !isVerification && verificationStatus !== "reject" && (
            <div className={css.footerProgressBar}>
              <ProgressBar isPickUp={isPickUp} />
            </div>
          )}
          {verificationStatus !== "consider" ? (
            <button
              className={css.footerButton}
              onClick={hasNextStep ? handleNextStep : handleInitiateVerification}
              disabled={(isVerification && !isProgressBarEnabled)}
            >
             {currentTextContent && currentTextContent.buttonText ? currentTextContent.buttonText : ''}
            </button>
          ) : (
            <button className={css.footerButton} onClick={handleCallButtonClick}>
              {currentTextContent.buttonText}   
            </button>
          )}
        </div>
        <div>
        </div>
      </div>
      {isDesktop && (
        <ModalHelp
          onClose={() => setShowCancelHelpModal(false)}
          cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
          isOpen={showCancelHelpModal}
        />
      )}
    </>
  );
};

export default IdentityVerificationComponent;
