import React from 'react';
import get from 'lodash/get';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROCESSING_FEE,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  txGetPromoLineItem,
  txIsCanceled,
  txIsCanceledByCustomer,
  txIsDeclined,
  txIsDelivered,
  txIsExpired,
} from '../../util/transaction';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemUnitPrice = props => {
  const {
    transaction,
    isProvider,
    intl,
    currentUser,
    isAddons,
    isTripDetailsPage,
    parentTx,
    isEditTripPage,
    totalAllTransactions,
    replaceTotalText,
    replaceTotal,
  } = props;

  const isDistanceChargingProcess = get(props, 'transaction.attributes.processName', '') === 'distance-charging';
  const isDistanceChargingTransaction = get(props, 'transaction.attributes.protectedData.isDistanceCharge', false);

  if (transaction.attributes.protectedData.calculateAsNewBooking) {
    return (
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          <FormattedMessage id="LineItemTotalPrice.totalExtensionLabel" />
        </div>
        {isTripDetailsPage && isProvider && txIsCanceled(transaction) ? (
          <div className={css.totalPrice}>0</div>
        ) : (
          <div className={css.totalPrice}>
            {formatMoney(intl, transaction.attributes.payinTotal, 2)}
          </div>
        )}
      </div>
    );
  }

  let isCanceledParentTxByCustomer = parentTx && txIsCanceledByCustomer(parentTx);

  const isUpdateBookingTx = transaction.attributes.processName === 'update-booking-charging';
  let updatedBookingPayinTotal = null;
  let updateBookingLineItems = [];
  if (isUpdateBookingTx) {
    updatedBookingPayinTotal = transaction.attributes.payinTotal.amount / 100;
    updateBookingLineItems = transaction.attributes.lineItems;
  }

  const customerCommissionLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
  const processingFeeLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE
  );
  const initialPaymentLineItem = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT
  );
  const initialPaymentLineItemReversed = updateBookingLineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT && item.reversal
  );

  let providerTotalMessageId = 'BookingBreakdown.providerTotalDefault';
  if (txIsDelivered(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDelivered';
  } else if (txIsDeclined(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalDeclined';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'BookingBreakdown.providerTotalCanceled';
  }

  const isLongTermRental = get(transaction, 'attributes.protectedData.isLongTermRental');
  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : isLongTermRental ? (
    <FormattedMessage id="BookingBreakdownLongTerm.total" />
  ) : (
    <FormattedMessage id="BookingBreakdown.total" />
  );

  const promoItem = txGetPromoLineItem(transaction);

  const promoAmount =
    promoItem && promoItem.lineTotal && !txIsCanceled(transaction) ? promoItem.lineTotal.amount : 0;

  const initialPayment =
    initialPaymentLineItem && initialPaymentLineItem.lineTotal && !txIsCanceled(transaction)
      ? initialPaymentLineItem.lineTotal.amount
      : 0;

  let processingFees = 0;
  if (isUpdateBookingTx && processingFeeLineItem) {
    processingFees = processingFeeLineItem.lineTotal.amount;
  } else {
    processingFees = get(
      transaction,
      'attributes.protectedData.commissionObj.commission.processingFees',
      0
    );
  }
  const payoutTotal = transaction.attributes.payoutTotal;
  const totalPrice = isProvider
    ? payoutTotal
    : txIsCanceledByCustomer(transaction) || isCanceledParentTxByCustomer
      ? new Money(processingFees, payoutTotal.currency)
      : transaction.attributes.payinTotal;

  const initialPaymentAmount = initialPaymentLineItemReversed ? 0 : Math.abs(initialPayment || 0);
  const finalTotalPrice = isProvider
    ? new Money(totalPrice.amount - (promoAmount || 0), totalPrice.currency)
    : isTripDetailsPage
      ? new Money(totalPrice.amount, totalPrice.currency)
      : new Money(totalPrice.amount - initialPaymentAmount, totalPrice.currency);

  const finalTotalPriceWithUpdatedBooking =
    updatedBookingPayinTotal !== null && isProvider
      ? new Money(
        finalTotalPrice.amount +
        (processingFeeLineItem.unitPrice - customerCommissionLineItem.unitPrice) * 100,
        finalTotalPrice.currency
      )
      : updatedBookingPayinTotal !== null && !isProvider
        ? new Money(finalTotalPrice.amount, finalTotalPrice.currency)
        : finalTotalPrice;

  const formattedTotalPrice = (isTripDetailsPage && !isDistanceChargingTransaction && !isDistanceChargingProcess && !isProvider)
    ? formatMoney(intl, new Money(totalAllTransactions - (isProvider ? promoAmount : 0), finalTotalPrice.currency), 2)
    : formatMoney(intl, finalTotalPriceWithUpdatedBooking, 2);

  const hostCredits =
    !isProvider || !currentUser || !currentUser.attributes
      ? 0
      : currentUser.attributes.credits
        ? currentUser.attributes.credits
        : 0;

  const hostCreditMoney =
    !hostCredits || hostCredits < 20
      ? null
      : formatMoney(intl, new Money(2000, totalPrice.currency), 2);

  return (
    <>
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>
          {replaceTotalText
            ? replaceTotalText
            : isAddons
              ? 'Total price'
              : isTripDetailsPage && isProvider
                ? 'Your earnings'
                : isEditTripPage
                  ? 'Balance due'
                  : 'Total due now'}
        </div>
        {/*<div className={css.totalPrice}>{`${formattedTotalPrice}${hostCreditMoney ? ` + ${hostCreditMoney} credits` : ''}`}</div>*/}
        {isTripDetailsPage && isProvider && (txIsCanceled(transaction) || txIsExpired(transaction)) ? (
          <div className={css.totalPrice}>0</div>
        ) : (
          <div className={css.totalPrice}>{replaceTotal ? replaceTotal : formattedTotalPrice}</div>
        )}
      </div>
      {!isProvider && (<div className={css.gstInclusionLine}>GST Included</div>)}
    </>
  );
};

LineItemUnitPrice.propTypes = {
  transaction: propTypes.transaction.isRequired,
  isProvider: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPrice;
