import React from 'react';
import css from './index.css'

const CodeGen = ({ codeNumber, text, isPickup }) => {
  return (
   <React.Fragment>
    {isPickup && <div className={css.lockBoxKeys}>
        <p>1. On the lockbox keypad, press ENT button.</p>
        <p>2. Enter the code mentioned below.</p>
        <p>3. Press ENT once again and access the keys.</p>
    </div>}
    <div className={css.unlockCodeWrapper}>
      {codeNumber && codeNumber.map((number, index) => (
          <span key={index} className={(codeNumber.length > 8) ? css.unlockcodeNo : (codeNumber.length > 6 ? css.unlockcode : '')}>{number}</span>
      ))}
    </div>

    {text && <span className={css.resetText}>{text}</span>}
   </React.Fragment>
  );
};

export default CodeGen;
