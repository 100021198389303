import classNames from 'classnames';
import { cloneDeep, get } from 'lodash';
import moment from 'moment-timezone';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import deliveryIcon from '../../assets/newCheckoutPage/deliveryIcon.svg';
import mileageIcon from '../../assets/tripPage/mileageIcon.svg';
import thumbIcon from '../../assets/tripPage/thumb.svg';
import premiumProtectionIcon from '../../assets/tripPage/Premium_Cover.png';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import FuelIcon from '../../assets/fuel.svg';
import TollIcon from '../../assets/toll.svg';
import {
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  restoreTransaction,
} from '../../util/data';
import {
  calculateBookingDays,
  getDefaultTimeZoneOnBrowser,
  minutesBetween,
} from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { createSlug, parse } from '../../util/urlHelpers';
import { updateUserData } from '../../ducks/user.duck';

import {
  AddOnsSection,
  AvatarMedium,
  Button,
  CheckoutBreakdownLongTerm,
  IconSpinner,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
} from '../../components';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { StripePaymentForm } from '../../forms';
import { formatMoney } from '../../util/currency';
import {
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionInitiateInvalidVoucher,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE,
  TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';

import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';
import {
  getMileageValuefromSliderMileageValue,
  sliderOptionsList,
} from '../../components/AddOnsSection/AddonsUtils';
import AlertBox from '../../components/AlertBox/AlertBox';
import LineItemBookingPeriodNew from '../../components/BookingBreakdown/LineItemBookingPeriodNew';
import {
  ADDON_NAME__MAXIMUM_PROTECTION,
  ADDON_NAME__MILEAGE_PACKAGE,
  ADDON_NAME__PREMIUM_PROTECTION,
} from '../../util/constants/addons';
import { distanceCountHelper } from '../../util/distanceCountHelper';
import {
  EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST,
  EVENT_BOOK_CREATED_REQUEST_GUEST,
  EVENT_BOOK_EXCESS_2_SUCCESS_GUEST,
  EVENT_BOOK_EXCESS_SUCCESS_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_HOST,
  EVENT_BOOK_SAVED_CARD_DETAILS,
  EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST,
  EVENT_BOOK_USED_PRESAVED_DETAILS,
  EVENT_DELIVERY_ADDON_ADDED,
  EVENT_DELIVERY_ADDON_REMOVED,
  EVENT_EXCESS_2_ADDON_ADDED,
  EVENT_EXCESS_2_ADDON_REMOVED,
  EVENT_EXCESS_ADDON_ADDED,
  EVENT_EXCESS_ADDON_REMOVED,
  EVENT_ORDER_COMPLETED,
  EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
  SEND_REQUEST_BOOKING_BUTTON_ID,
  SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST,
  SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST,
} from '../../util/gtm/gtmConstants';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import {
  initiateEventFromTransaction,
  initiateExperimentEventFromListing,
  pushDataLayer,
  pushGTMBookEvent,
  pushGTMBookEventAfterOrderCompleted,
} from '../../util/gtm/gtmHelpers';
import { calculateLongTermPriceWithFeeOneMonth } from '../../util/longTermRentalHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  sendSmoveUserBookingNotification,
  sendStripeErrorSlackNotification,
} from '../../util/slackNotify';
import { getUpdateObjs, makeChildUpdateBookingDates } from '../../util/updateTransaction';
import { handlePaymentDistance, requestToUpdateBooking } from '../TransactionPage/TransactionPage.duck';
import css from './CheckoutPage.css';
import {
  confirmPayment,
  confirmPaymentDeposit,
  fetchDataForUpdateBookingCheckoutPage,
  fetchDepositTx,
  initiateOrder,
  initiatingDistanceCharge,
  makeUpdateBookingRequest,
  sendMessage,
  setInitialValues,
  speculateTransaction,
  speculateTransaction2,
  stripeCustomer,
} from './AfterDropOffPage.duck';
import {
  $isInitiateUpdateBookingRequestPending,
  $isInitiateUpdateBookingRequestSuccess,
} from './CheckoutPage.selectors';
import { checkCanUseMastercardPromo } from './CheckoutPage.helpers';
import { GTAG_ACTIONS, sendG4AEvent } from '../../util/gtag';
import { clearData, storeData, storedData } from './CheckoutPageSessionHelpers';
import Axios from 'axios';
import { showTransaction } from '../TripDetailsPage/TripDetailsPage.duck';

const STORAGE_KEY = 'CheckoutPage';
const LIMIT_DISTANCE = 6;
const LIMIT_START_HOUR = 9;
const LIMIT_END_HOUR = 21;
let particularVASParams = {
  booking_vas_excess: false,
  booking_vas_excess_2: false
};

let EXPERIMENT_DATA = {};
let initialPageData = null;
let mapAbSegment = '';

const { LatLng } = sdkTypes;
// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
      ? PAY_AND_SAVE_FOR_LATER_USE
      : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
      ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
      : false;
};

export const checkoutStepConfiguration = {
  CHECKOUT_ADD_ONS: 'addOns',
  CHECKOUT_PAYMENT: 'payments',
};

export class AfterDropOffPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      isRetryButtonClicked: false,
      shouldRedirectToSearchPage: false,
      isUsingMastercard: false,
      enteredCard: null,
      nextLocation: {},
      isOpenConfirmLeaveModal: false,
      isBlocking: true,
      // isDeliveryState: false,
      totalAmount: 0,
      // isPtotectionPlanState: false,
      // isFuelInclusionState: false,
      // checkoutStep: checkoutStepConfiguration.CHECKOUT_PAYMENT,
      // checkoutStepName: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
      // isExcessReductionState: false,
      // isExcessReduction2State: false,
      // isMileagePackageIncludedState: false,
      // isFuelSelectedManually: false,
      isAlert: false,
      // selectedMileage: 0,
      isDistanceFetched: false,
      distanceTravelled: '',
      fetchingDistanceTransaction: false,
      parentTransaction: null,
      distanceTransaction: null,
      distanceTransactionId: null,
      errorInPayment: null,
    };
    this.stripe = null;
    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.pushEventEnterFormGTM = this.pushEventEnterFormGTM.bind(this);
    // this.handleChangeAddOn = this.handleChangeAddOn.bind(this);
    // this.handleSelectedMileageAddOn = this.handleSelectedMileageAddOn.bind(this);
  }

  componentDidMount() {
    if (window) {
      // this.loadInitialData();
      this.fetchDistanceCharges();
      this.props.fetchStripeCustomer();
    }
  }

  async fetchDistanceCharges() {
    this.setState({fetchingDistanceTransaction: true});
    if (this.props.distanceTransaction) {
      this.setState({distanceTransaction: this.props.distanceTransaction});
      this.setState({fetchingDistanceTransaction: false});

      localStorage.setItem(`distanceTransactionFor_${this.props.params.id}`, JSON.stringify(this.props.distanceTransaction));

    } else if (!this.props.distanceTransaction) {
      const distanceTx = await JSON.parse(localStorage.getItem(`distanceTransactionFor_${this.props.params.id}`));

      this.setState({distanceTransaction: distanceTx});
      this.setState({fetchingDistanceTransaction: false});
    }

  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      currentUser,
      timeSlotsObj,
      onFetchDataForUpdateBookingCheckoutPage,
    } = this.props;

    if (this.props.transaction) {
      this.setState({ transaction: this.props.transaction });
      localStorage.setItem('afterDropOffTransactionData', JSON.stringify(this.props.transaction));
    } else if (!this.props.transaction) {
      const transaction = JSON.parse(localStorage.getItem('afterDropOffTransactionData'));
      this.setState({ transaction: transaction });
    }

    console.log('State in load data >>', this.state.transaction);



    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();


    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    console.log('AfterDropoffPage >>> hasNavigatedThroughLink >>>', hasNavigatedThroughLink);

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;

    console.log('AfterDropoffPage >>> hasDataInProps >>>', hasDataInProps);

    console.log('AfterDropoffPage >>> currentUser >>>', currentUser);

    if (hasDataInProps && currentUser) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, currentUser, STORAGE_KEY, {
        timeSlotsObj,
      });
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction, timeSlotsObj }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    console.log('AfterDropoffPage >>> pageData >>>', pageData);

    const shouldFetchSpeculatedTransaction = true;
    // pageData &&
    // pageData.listing &&
    // pageData.listing.id &&
    // pageData.bookingData &&
    // pageData.bookingDates &&
    // pageData.bookingDates.bookingStart &&
    // pageData.bookingDates.bookingEnd &&
    // (currentUser || pageData.temporaryUserData) &&
    // !isBookingCreated;

    console.log('AfterDropoffPage >>> shouldFetchSpeculatedTransaction >>>', shouldFetchSpeculatedTransaction);

    if (shouldFetchSpeculatedTransaction) {

      const data = this.state;
      console.log('AFTER DROP OFF DATA >>', data);

      const listingId = pageData.listing.id;
      const user = currentUser ? currentUser : pageData.temporaryUserData;
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { listing } = pageData;
      const {
        isPaidAmount,
        paidAmount,
        diffHours,
        tripBookingStart,
        tripBookingEnd,
        updateBookingStart,
        updateBookingEnd,
      } = pageData.bookingData;
      const { location } = this.props;

      const commercialListing = listing.attributes.publicData.insurance === 'commercial';

      const isMinimumPrice = listing.attributes.price.amount <= 6000;
      const currentYear = new Date().getFullYear();
      const youngDriver =
        user &&
        user.attributes.profile.protectedData &&
        user.attributes.profile.protectedData.dateOfBirth &&
        currentYear - user.attributes.profile.protectedData.dateOfBirth.year <= 24;

      let bookingProcess = isPaidAmount
        ? config.updateBookingChargingProcessAlias
        : config.masterProcessAlias;
      let transition = null;
      if (!youngDriver) {
        if (commercialListing) {
          transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
        } else {
          transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
        }
      } else {
        if (commercialListing) {
          transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
        } else {
          transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
        }
      }

      if (isPaidAmount) {
        transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
      }
      const currentBookingDateStart = new Date(
        new Date(moment(tripBookingStart)).toISOString().slice(0, -1)
      );
      const currentBookingDateEnd = new Date(
        new Date(moment(tripBookingEnd)).toISOString().slice(0, -1)
      );
      const currentBookingStart = new Date(
        new Date(moment(updateBookingStart)).toISOString().slice(0, -1)
      );
      const currentBookingEnd = new Date(
        new Date(moment(updateBookingEnd)).toISOString().slice(0, -1)
      );
      const displayBookingDateStart = new Date(moment(updateBookingStart));
      const displayBookingDateEnd = new Date(moment(updateBookingEnd));

      const timeFromTripStart = moment().diff(moment(displayBookingDateStart), 'hours', true);
      const timeFromThreeDays = moment().add(3, 'days');

      const speculatedData = {
        listingId,
        bookingStart: location.state && location.state.updateBooking ? bookingStart : bookingStart,
        bookingEnd: location.state && location.state.updateBooking ? bookingEnd : bookingEnd,
        bookingDisplayStart:
          location.state && location.state.updateBooking ? bookingStart : bookingStart,
        bookingDisplayEnd: location.state && location.state.updateBooking ? bookingEnd : bookingEnd,
        customerPhoneNumber: user.attributes.profile.protectedData.phoneObj,
        customerLocation: user.attributes.profile.protectedData.location,
        isDrivelahGo: !!listing.attributes.metadata.isDrivelahGo,
        credits: pageData.bookingData.signupCredits,
        voucherCode: pageData.bookingData.voucherCode,
        payForFuel: pageData.bookingData.payForFuel,
        isPaidAmount,
        diffHours,
        currentTx: pageData.bookingData.currentTx,
        ...(pageData.bookingData.peakHoursDiff > 0 && {
          peakHoursDiff: pageData.bookingData.peakHoursDiff,
        }),
        ...(pageData.bookingData.regularHoursDiff > 0 && {
          regularHoursDiff: pageData.bookingData.regularHoursDiff,
        }),
        // paidAmount,
        isDelivery: this.state.isDeliveryState,
        isExcessReduction: this.state.isExcessReductionState,
        isExcessReduction2: this.state.isExcessReduction2State,
        isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
        // isMileagePackageIncluded: true,
        selectedMileage: this.state.selectedMileage,
        mileageExperiment: mapAbSegment,
        currentBookingStart:
          location.state && location.state.updateBooking
            ? moment(currentBookingStart).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingEnd:
          location.state && location.state.updateBooking
            ? moment(currentBookingEnd).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingStartTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingStart).getTime()
            : null,
        currentBookingEndTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingEnd).getTime()
            : null,
        currentBookingDateStart:
          location.state && location.state.updateBooking
            ? moment(currentBookingDateStart).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingDateEnd:
          location.state && location.state.updateBooking
            ? moment(currentBookingDateEnd).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingDateStartTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingDateStart).getTime()
            : null,
        currentBookingDateEndTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingDateEnd).getTime()
            : null,
        stripeTKey: pageData.bookingData.stripeTKey,
        displayBookingDateStart:
          location.state && location.state.updateBooking
            ? moment(displayBookingDateStart).format('MMM DD, YYYY HH:mm')
            : null,
        displayBookingDateEnd:
          location.state && location.state.updateBooking
            ? moment(displayBookingDateEnd).format('MMM DD, YYYY HH:mm')
            : null,
      };

      console.log('I am speculate data >>', speculatedData);


      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData

      // fetchSpeculatedTransaction(
      //   speculatedData,
      //   bookingProcess,
      //   isMinimumPrice,
      //   pageData.listing,
      //   transition
      // );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onConfirmPaymentDeposit,
      // onCheckBookingOverlap,
      fetchedDepositTransaction,
    } = this.props;
    const { shouldReduceBookingDeposit, isFuelInclusionState: isFuelInclusion } = this.state;

    const {
      pageData,
      // speculatedTransaction,
      distanceTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(distanceTransaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const { bookingProcess, isMinimumPrice, listing, ...fnRestParams } = fnParams;
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(
          fnRestParams,
          storedTx.id,
          bookingProcess,
          isMinimumPrice,
          listing,
          selectedPaymentFlow,
          handlePaymentParams,
          shouldReduceBookingDeposit
        );
    };

    // const fnRequestUpdateBooking = fnParams => {
    //   const { onRequestToUpdateBooking, location } = this.props;
    //   const updateBooking =
    //     location.state && location.state.updateBooking ? location.state.updateBooking : false;

    //   if (updateBooking) {
    //     const updateBookingParams = pageData.bookingData.updateBookingParams;
    //     const { transaction, transition, transactionId, listing } = updateBookingParams;

    //     const {
    //       updateBookingStart,
    //       updateBookingEnd,
    //       currentTx,
    //       regularHoursDiff,
    //       peakHoursDiff,
    //     } = pageData.bookingData;

    //     const params = {
    //       createUpdateBooking: true,
    //       transaction,
    //       transition,
    //       transactionId,
    //       listing,
    //       bookingStart: updateBookingStart,
    //       bookingEnd: updateBookingEnd,
    //       currentTx,
    //       regularHoursDiff,
    //       peakHoursDiff,
    //       protectedData: {
    //         childTransaction: [
    //           ...getUpdateObjs(transaction),
    //           {
    //             ...makeChildUpdateBookingDates(updateBookingStart, updateBookingEnd),
    //             txId: fnParams.id.uuid,
    //           },
    //         ],
    //       },
    //     };

    //     onRequestToUpdateBooking(params);
    //     initiateEventFromTransaction({
    //       props: this.props,
    //       transaction: pageData.transaction,
    //       event: EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
    //     });
    //   }

    //   return fnParams;
    // };

    const fnHandleDepositCardPayment = fnParams => {
      const order = ensureTransaction(fnParams);
      const depositTx = order.deposit || fetchedDepositTransaction;
      if (!depositTx) {
        return Promise.resolve({
          mainTx: order,
          deposit: false,
        });
      }
      const hasPaymentIntents =
        depositTx.attributes.protectedData &&
        depositTx.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? depositTx.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentDepositIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};
      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const hasPaymentIntentUserActionsDone =
        paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status);
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
            payment_method_data: {
              billing_details: billingDetails,
            },
          }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: depositTx.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order: depositTx,
        userId: this.props.currentUser.id.uuid,
        mainTx: order,
        deposit: true,
      };
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({
          orderId: depositTx.id,
          paymentIntent: paymentDepositIntent,
          order: depositTx,
          mainTx: order,
          deposit: true,
          userId: this.props.currentUser.id.uuid,
        })
        : onHandleCardPayment(params);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, currentUser, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;
      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
            payment_method_data: {
              billing_details: billingDetails,
            },
          }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order,
        userId: this.props.currentUser.id.uuid,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent, order })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPaymentDeposit = ({ deposit, transactionId, order, mainTx }) => {
      if (deposit) {
        return onConfirmPaymentDeposit({
          order,
        }).then(() => {
          return mainTx;
        });
      } else {
        return Promise.resolve(mainTx);
      }
    };
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      const { listing, bookingData } = pageData;
      const { location } = this.props;
      const { isPaidAmount } = bookingData;
      const transactionId = location.state && location.state.transactionId;

      return onConfirmPayment({
        ...fnParams,
        savedListing: listing,
        isPaidAmount,
        userId: this.props.currentUser.id.uuid,
        parentTransaction: { transactionId },
      });
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      // fnRequestUpdateBooking,
      fnHandleDepositCardPayment,
      fnConfirmPaymentDeposit,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    const { listing, bookingData } = this.state.pageData;
    const { isPaidAmount } = bookingData;

    const isMinimumPrice = listing.attributes.price.amount <= 6000;
    const commercialListing = listing.attributes.publicData.insurance === 'commercial';
    const currentYear = new Date().getFullYear();
    const youngDriver =
      currentUser &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.dateOfBirth &&
      currentYear - currentUser.attributes.profile.protectedData.dateOfBirth.year <= 24;

    // let bookingProcess = config.masterProcessAlias;
    let bookingProcess = isPaidAmount
      ? config.updateBookingChargingProcessAlias
      : config.masterProcessAlias;
    let transition = null;
    if (!youngDriver) {
      if (commercialListing) {
        transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
      } else {
        transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
      }
    } else {
      if (commercialListing) {
        transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
      } else {
        transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
      }
    }

    if (isPaidAmount) {
      transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
    }

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = distanceTransaction;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const { hasMastercardPromoBeenUsed } = currentUser.attributes.profile.metadata;
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
          ? { setupPaymentMethodForSaving: true }
          : {};

    const shouldUseMastercardPromoLineItem =
      this.state.isUsingMastercard && checkCanUseMastercardPromo(currentUser);

    const orderParams = {
      transition,
      listingId: pageData.listing.id,
      bookingStart: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayStart)
        : tx.booking.attributes.start,
      bookingEnd: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayEnd)
        : tx.booking.attributes.end,
      bookingDisplayStart: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayStart)
        : tx.booking.attributes.displayStart,
      bookingDisplayEnd: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayEnd)
        : tx.booking.attributes.displayEnd,
      protectedData: tx.attributes.protectedData,

      preauthenListingId: config.preauthenListingId,
      depositListingId: config.depositListingId,
      preauthenListingIdWithDepositDiscount: config.preauthenListingIdWithDepositDiscount,
      quantity: isPaidAmount
        ? calculateBookingDays(
          tx.attributes.protectedData.bookingDisplayStart,
          tx.attributes.protectedData.bookingDisplayEnd
        )
        : calculateBookingDays(
          tx.booking.attributes.displayStart,
          tx.booking.attributes.displayEnd
        ),
      credits: pageData.bookingData.signupCredits,
      voucherCode: pageData.bookingData.voucherCode,
      isPaidAmount: pageData.bookingData.isPaidAmount,
      ...(pageData.bookingData.regularHoursDiff &&
        pageData.bookingData.regularHoursDiff > 0 && {
        regularHoursDiff: pageData.bookingData.regularHoursDiff,
      }),
      ...(pageData.bookingData.peakHoursDiff &&
        pageData.bookingData.peakHoursDiff > 0 && {
        peakHoursDiff: pageData.bookingData.peakHoursDiff,
      }),
      currentTx: pageData.bookingData.currentTx,
      diffHours: pageData.bookingData.diffHours,
      // paidAmount: pageData.bookingData.paidAmount,
      isMinimumPrice,
      bookingProcess,
      listing: pageData.listing,
      userId: this.props.currentUser.id.uuid,
      shouldUseMastercardPromoLineItem,
      isDelivery: this.state.isDeliveryState,
      isExcessReduction: this.state.isExcessReductionState,
      ...(isFuelInclusion ? { isFuelInclusion } : {}),
      isExcessReduction2: this.state.isExcessReduction2State,
      mileageExperiment: mapAbSegment,
      isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
      selectedMileage: this.state.selectedMileage,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  async handleSubmit(values) {
    this.setState({submitting: true});
    const {distanceTransaction} = this.state;

    const distanceTravelled = get(distanceTransaction, 'attributes.protectedData.distanceTravelled', '')

    const params = {
      distanceTravelled: distanceTravelled,
      currentTransactionId: this.props.params.id,
      distanceChargingTransaction: distanceTransaction,
      interComTicketExisitsForDistance: this.props.intercomTicketIdForDistance,
    }

    try {
      const payment = await this.props.initiatingDistanceCharge(params);
      this.setState({submitting: false});
    } catch (error) {
      this.setState({errorInPayment: true});
      this.setState({submitting: false});
    }
  }

  onRetrievePaymentDepositIntent = ({ depositId, stripe }) => {
    const { onFetchDepositTx, onRetrievePaymentIntent } = this.props;
    onFetchDepositTx({ id: depositId }).then(deposit => {
      const { stripePaymentIntentClientSecret } =
        deposit.attributes.protectedData && deposit.attributes.protectedData.stripePaymentIntents
          ? deposit.attributes.protectedData.stripePaymentIntents.default
          : {};
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret, deposit: true });
    });
  };

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe && !paymentIntent && tx && tx.id && !checkIsPaymentExpired(tx);
    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};
      const { depositTx } = tx.attributes.protectedData || {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
      if (depositTx) {
        this.onRetrievePaymentDepositIntent({ depositId: depositTx, stripe });
      }
    }
  }

  handleNavigation = nextLocation => {
    if (this.props.isInitiateUpdateBookingRequestSuccess) {
      return;
    }
    // this.setState({ isOpenConfirmLeaveModal: true, nextLocation });
    // if (!this.state.isBlocking) return true;
    // return false;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };

  onConfirmLeave = () => {
    const { nextLocation } = this.state;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };



  getTotalPrice = totalAmount => {
    this.setState({
      totalAmount,
    });
  };

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      onManageDisableScrolling,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      location,
      bookingOverlapError,
      retrievePaymentDepositIntentError,
      paymentDepositIntent,
      transaction,
      rootTx,
      creatingDistanceTransaction,
      distanceTransaction,
      parentTransactionId,
      paymentForDistanceSuccessful,
    } = this.props;

    console.log('After drop off page > props', this.props);
    console.log('After drop off page > state', this.state);

    if (paymentForDistanceSuccessful) {
      const currentUrl = window.location.pathname;
      const baseUrl = currentUrl.split('/after-drop-off')[0];
      const newUrl = `${baseUrl}/details`;
      this.props.history.push(newUrl);
      window.location.reload();
    }
    
    const isLoading = this.state.fetchingDistanceTransaction && !this.state.distanceTransaction && !stripeCustomerFetched;


    const {
      isBlocking = true,
      isOpenConfirmLeaveModal,
      checkoutStep,
      isFuelInclusionState: isFuelInclusion,
    } = this.state;
    const isLongTermRental = get(
      speculatedTransactionMaybe,
      'attributes.protectedData.isLongTermRental'
    );

    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    
    const pageProps = { title: 'Failed payment for distance', scrollingDisabled: false, className: css.root };

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return (
        <Page {...pageProps}>
          {topbar}
          <div className={css.loading}>
            <IconSpinner />
            <div>Please wait...</div>
          </div>
        </Page>
      );
    }


    const price = get(this.state.distanceTransaction, 'attributes.payinTotal', {})

    const priceToPay = this.state.distanceTransaction &&  formatMoney(intl, price, 1);


    console.log('After DropOff Page > priceToPay > ', priceToPay);
    

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    const showPaymentForm = true;

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    const showInitialMessageInput =
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE;

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName = null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      (paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status)) ||
      (paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status));

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
    const { blockNo, building, floorUnit, city, country, postalCode } = protectedData;
    const { selectedPlace } = protectedData.location || {};
    const { address, origin } = selectedPlace || {};

    const initalValuesForStripePayment = {
      name: userName,
      blockNo,
      addressLine1: blockNo + ' ' + address,
      addressLine2: floorUnit,
      location: {
        search: address,
        selectedPlace: {
          address,
          origin: new LatLng(origin && origin.lat, origin && origin.lng),
        },
      },
      building,
      floorUnit,
      city,
      country,
      postalCode,
      postal: postalCode,
    };

    const isUpdateBooking = get(location, 'state.updateBooking', false);

    console.log('AfterDropOffPage > initalValuesForStripePayment > ', initalValuesForStripePayment);
    

    return (
      <>
        <Prompt when={isBlocking} message={this.handleNavigation} />
        {/* <Modal
          isOpen={isOpenConfirmLeaveModal}
          onClose={() => {
            this.setState({ isOpenConfirmLeaveModal: false });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {leavingPopup}
        </Modal> */}
        <Page {...pageProps}>
          {topbar}
          {/* {showErrorAlert} */}
          { this.state.errorInPayment && (
            <AlertBox
              title="Payment Failed"
              message='Payment for distance failed, please try again.'
              type="error"
            />
          )}
          <div className={css.contentContainer}>
            <div className={css.contentRow}>
              <div className={css.checkoutPageTitles}>
                <div className={css.stepTitle}>
                  Trip end payment failed
                </div>
              </div>
            </div>
            <div className={css.contentRow}>
              <div className={css.checkoutSectionLeft}>
                <div className={css.errorForFailedTransaction}>
                  <p>{`Payment for ${priceToPay ? priceToPay : 'distance charging'} failed`}</p>
                  {/* <p>{`Payment for $123 failed`}</p> */}
                  <p>
                    <p>
                      Your card was declined
                    </p>
                    <p>
                      Our payment provider declined the card due to low balance. Please use a different payment method.
                    </p>
                  </p>
                </div>
                <div className={css.bookListingContainer}>
                  {/* TODO detailsContainerLeft */}
                  <section className={css.paymentContainer}>
                    {/* {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                      <p className={css.orderError}>
                        <FormattedMessage
                          id="CheckoutPage.retrievingStripePaymentIntentFailed"
                          values={{ listingLink }}
                        />
                      </p>
                    ) : null} */}
                    {/* {retryButton} */}
                    {showPaymentForm ? (
                      <StripePaymentForm
                        className={css.paymentForm}
                        onSubmit={this.handleSubmit}
                        inProgress={this.state.submitting}
                        formId="CheckoutPagePaymentForm"
                        paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                        // authorDisplayName={currentAuthor.attributes.profile.displayName}
                        showInitialMessageInput={showInitialMessageInput}
                        initialValues={initalValuesForStripePayment}
                        initiateOrderError={initiateOrderError}
                        handleCardPaymentError={handleCardPaymentError}
                        confirmPaymentError={confirmPaymentError}
                        currentUser={currentUser}
                        transaction={this.state.distanceTransaction}
                        isProvider={false}
                        intl={intl}
                        hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                        loadingData={!stripeCustomerFetched}
                        defaultPaymentMethod={
                          hasDefaultPaymentMethod
                            ? currentUser.stripeCustomer.defaultPaymentMethod
                            : null
                        }
                        isUsingMastercard={this.state.isUsingMastercard}
                        setIsUsingMastercard={val => {
                          this.setState({ isUsingMastercard: val });
                        }}
                        paymentIntent={paymentIntent}
                        onStripeInitialized={this.onStripeInitialized}
                        // isInstantBooking={isInstantBooking}
                        submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                        pushEventEnterFormGTM={this.pushEventEnterFormGTM}
                        isLongTermRental={isLongTermRental}
                        // isPayForFuel={isPayForFuel}
                        isFuelInclusion={isFuelInclusion}
                        // isDelivery={showDelivery}
                        isTotalPriceVisible={true}
                        isDeliveryState={this.state.isDeliveryState}
                        onChangeDelivery={this.onDeliveryChange}
                        isUpdateBooking={isUpdateBooking}
                        isAfterDropoffPage={true}
                      />
                    ) : null} 
                    {/* ====== Just commenting out for reference , uncomment it later ====== */}
                   {/* {isPaymentExpired ? (
                        <p className={css.orderError}>
                          <FormattedMessage
                            id="CheckoutPage.paymentExpiredMessage"
                            values={{ listingLink }}
                          />
                        </p>
                      ) : null} */}
                  </section>

                  {this.state.distanceTransaction && (<div className={css.priceBreakdownContainer}>
                    <h3>Charge Details</h3>
                    {this.state.distanceTransaction && (
                      <BookingBreakdownNew
                      // timeZone={timeZone}
                      className={css.bookingBreakdown}
                      // isCheckoutPage={true}
                      userRole="customer"
                      currentUser={currentUser}
                      unitType={config.bookingUnitType}
                      transaction={this.state.distanceTransaction}
                      // booking={txBooking}
                      // shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
                      getTotalPrice={this.getTotalPrice}
                      // checkoutStep={this.state.checkoutStep}
                      // changeCheckoutStep={step => this.setState({ checkoutStep: step })}
                      replaceTotalText={'Total Due'}
                      isDropOff={true}
                      isTripDetailsPage={true}
                      chargeForDistance={true}
                    />
                    )}
                  </div>)}
                </div>

              </div>

              <div className={css.checkoutSectionRight}>
                <div className={css.detailsContainerDesktop}>
                  {this.state.distanceTransaction && (<div className={css.detailsContainerMain}>
                    <h3>Charge Details</h3>
                    {this.state.distanceTransaction && (
                      <BookingBreakdownNew
                      // timeZone={timeZone}
                      className={css.bookingBreakdown}
                      // isCheckoutPage={true}
                      userRole="customer"
                      currentUser={currentUser}
                      unitType={config.bookingUnitType}
                      transaction={this.state.distanceTransaction}
                      // booking={txBooking}
                      // shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
                      getTotalPrice={this.getTotalPrice}
                      // checkoutStep={this.state.checkoutStep}
                      // changeCheckoutStep={step => this.setState({ checkoutStep: step })}
                      replaceTotalText={'Total Due'}
                      isDropOff={true}
                      isTripDetailsPage={true}
                      chargeForDistance={true}
                    />
                    )}
                  </div>)}
                  {speculateTransactionErrorMessage}
                </div>
              </div>
            </div>
          </div>
        </Page>
      </>
    );
  }
}

AfterDropOffPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

AfterDropOffPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  fetchSpeculatedTransaction2: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  makeUpdateBookingRequest: func.isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    bookingOverlapError,
    timeSlotsObj,
    depositTx,
    rootTx,
    distanceTransaction,
    parentTransactionId,
    paymentForDistanceSuccessful,
    intercomTicketIdForDistance,
  } = state.AfterDropOffPage;
  const { currentUser } = state.user;
  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
  } = state.stripe;
  console.log('After Dropoff > very begining >',rootTx);
  
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    bookingOverlapError,
    timeSlotsObj,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    isInitiateUpdateBookingRequestPending: $isInitiateUpdateBookingRequestPending(state),
    isInitiateUpdateBookingRequestSuccess: $isInitiateUpdateBookingRequestSuccess(state),
    fetchedDepositTransaction: depositTx,
    rootTx,
    distanceTransaction,
    parentTransactionId,
    paymentForDistanceSuccessful,
    intercomTicketIdForDistance,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchingTransactionDetails: id => dispatch(showTransaction(id)),
  recallingInitiateAPI: params => dispatch(handlePaymentDistance(params)),
  initiatingDistanceCharge: params => dispatch(initiatingDistanceCharge(params)),
  fetchSpeculatedTransaction: (
    params,
    bookingProcess = null,
    minimumPrice = false,
    savedListing = null,
    transition
  ) =>
    dispatch(speculateTransaction(params, bookingProcess, minimumPrice, savedListing, transition)),
  fetchSpeculatedTransaction2: (
    params,
  ) =>
    dispatch(speculateTransaction2(params)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
  onInitiateOrder: (
    params,
    transactionId,
    bookingProcess = null,
    minimumPrice = false,
    savedListing = null,
    selectedPaymentFlow = null,
    handlePaymentParams = null
  ) =>
    dispatch(
      initiateOrder(
        params,
        transactionId,
        bookingProcess,
        minimumPrice,
        savedListing,
        selectedPaymentFlow,
        handlePaymentParams
      )
    ),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmPaymentDeposit: params => dispatch(confirmPaymentDeposit(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  // onCheckBookingOverlap: (listingId, start, end) =>
  // dispatch(checkBookingOverlap(listingId, start, end)),
  onFetchDepositTx: params => dispatch(fetchDepositTx(params)),
  onFetchDataForUpdateBookingCheckoutPage: updateBookingData => dispatch(fetchDataForUpdateBookingCheckoutPage(updateBookingData)),
  onMakeUpdateBookingRequest: (updateBookingData, history) => dispatch(makeUpdateBookingRequest(updateBookingData, history)),
  onUpdateUserData: params => dispatch(updateUserData(params)),
});

const AfterDropOffPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AfterDropOffPageComponent);

AfterDropOffPage.setInitialValues = initialValues => setInitialValues(initialValues);

AfterDropOffPage.displayName = 'AfterDropOffPage';

export default AfterDropOffPage;