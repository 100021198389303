import React, { memo } from 'react';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_UNITS,
} from '../../util/types';

import css from './BookingBreakdown.css';

const { Money } = sdkTypes;

const LineItemModifyTotal = memo(props => {
  const { intl, transaction } = props;

  const modifyTxTotal = transaction.attributes.lineItems.reduce((acc, curr) => {
    const noIcludedLineItems = [
      LINE_ITEM_UNITS,
      LINE_ITEM_INITIAL_PAYMENT,
      LINE_ITEM_INITIAL_PAYMENT_PROVIDER,
      LINE_ITEM_PROVIDER_COMMISSION,
    ];

    if (noIcludedLineItems.indexOf(curr.code) === -1) {
      acc += curr.lineTotal.amount;
    }

    return acc;
  }, 0);

  const modifiedTotal = new Money(modifyTxTotal, 'AUD');

  const formattedTotal = formatMoney(intl, modifiedTotal, 2);

  return (
    <div className={css.lineItem}>
      <hr className={css.totalDivider} />

      <span className={css.itemTotalLabel}>Total</span>

      <span className={css.itemTotalValue}>{formattedTotal}</span>
    </div>
  );
});

export default LineItemModifyTotal;
