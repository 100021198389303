  import React, { useState, useEffect, useRef } from "react";
  import styles from "./index.css";

  const FuelGauge = ({ nettFuel, setNettFuel }) => {
    const [fuelLevel, setFuelLevel] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const gaugeRef = useRef(null);
    
    const calculateFuelLevel = (clientY) => {
      const gaugeElement = gaugeRef.current.getBoundingClientRect();
      const newFuelLevel = ((clientY - gaugeElement.top) / gaugeElement.height) * 100;
      const formattedFuelLevel = (100 - newFuelLevel).toFixed(2);
      return Math.max(0, Math.min(100, parseFloat(formattedFuelLevel)));
    };

    const handleDrag = (e) => {
      if (!isDragging) return;

      const clientY = e.touches ? e.touches[0].clientY : e.clientY;
      const clampedFuelLevel = calculateFuelLevel(clientY);
      const adjustment = 0.6;
      setFuelLevel(clampedFuelLevel);
      setNettFuel(clampedFuelLevel + adjustment);
    };



    const startDrag = () => setIsDragging(true);
    const stopDrag = () => setIsDragging(false);

    useEffect(() => {
      setFuelLevel(nettFuel - 0.6);
    }, []);

    useEffect(() => {
      const gaugeElement = gaugeRef.current;
  
      gaugeElement.addEventListener("touchmove", handleDrag, { passive: false });
      gaugeElement.addEventListener("touchstart", startDrag, { passive: false });
      gaugeElement.addEventListener("touchend", stopDrag, { passive: false });
  
      return () => {
        gaugeElement.removeEventListener("touchmove", handleDrag);
        gaugeElement.removeEventListener("touchstart", startDrag);
        gaugeElement.removeEventListener("touchend", stopDrag);
      };
    }, [isDragging]);

    const handleClick = (e) => {
      const clientY = e.clientY;
      const clampedFuelLevel = calculateFuelLevel(clientY);
      const adjustment = 0.6;
      setFuelLevel(clampedFuelLevel);
      setNettFuel(clampedFuelLevel + adjustment);
    };

    useEffect(() => {
      const stopDragging = () => setIsDragging(false);

      document.addEventListener("mouseup", stopDragging);
      document.addEventListener("touchend", stopDragging, { passive: false });

      return () => {
        document.removeEventListener("mouseup", stopDragging);
        document.removeEventListener("touchend", stopDragging);
      };
    }, []);

    return (
      <div className={styles.container}>
        <div className={styles.label}>Visually mark the fuel level</div>
        <div className={styles.instructions}>
          On the meter below please mark the current fuel level. Ensure it's the same as in the photo you took.
        </div>
        <div
          className={styles.gaugeContainer}
          ref={gaugeRef}
          onMouseMove={handleDrag}
          onMouseDown={startDrag}
          onMouseUp={stopDrag}
          onMouseLeave={stopDrag}
          onClick={handleClick}
        >
          <div className={styles.fuelGauge} style={{ height: `${fuelLevel}%` }}></div>
          <div className={styles.dragger} style={{ bottom: `${fuelLevel}%` }}></div>
          <div className={styles.breakPoints}>
            <span className={styles.breakPointLabel}>100%</span>
            <span className={styles.breakPointLabel}>75%</span>
            <span className={styles.breakPointLabel}>50%</span>
            <span className={styles.breakPointLabel}>25%</span>
            <span className={styles.breakPointLabel}>0%</span>
            <div className={styles.gridLines}></div>
          </div>
          <div className={styles.guageContainer}>
            {
              [1, 2, 3, 4].map((marks) => (
                <div key={`mark-${marks}`} className={styles.mark}>
                  <div className={styles.mainMark}></div>
                  <div className={styles.smallMarksContainer}>
                    {[1, 2, 3, 4].map((smallMarks) => (
                      <div key={`smallMarks-${smallMarks}`} className={styles.smallMark}></div>
                    ))}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  };

  export default FuelGauge;
