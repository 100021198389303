import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { txIsCanceledByCustomer } from '../../util/transaction';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
} from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';

const LineItemProcessingFeeRefundMaybe = (
  {
    transaction,
    isCustomer,
    intl,
    isAddons,
    parentTx,
  }
) => {
  let isCanceledParentTxByCustomer = parentTx && txIsCanceledByCustomer(parentTx);
  const customerCommissionLineItem = transaction.attributes.lineItems.find(
    item =>
      (isAddons
        ? item.code === LINE_ITEM_PROCESSING_FEE_ADDONS
        : item.code === LINE_ITEM_PROCESSING_FEE) && item.reversal
  );

  const initialPaymentLineItem = transaction && transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_INITIAL_PAYMENT && !item.reversal
  );

  if (isCustomer && initialPaymentLineItem) return null;

  if (!isCustomer ||
    !customerCommissionLineItem ||
    txIsCanceledByCustomer(transaction) ||
    isCanceledParentTxByCustomer
  ) {
    return null;
  }

  const commission = customerCommissionLineItem.lineTotal;

  const formattedCommission = commission ? formatMoney(intl, commission, 1) : null;

  return formattedCommission ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.processingFeeRefund" />
        <Explanation text="Payment provider charges" />
      </span>
      <span className={css.itemValue}>{formattedCommission}</span>
    </div>
  ) : null;
};

export default LineItemProcessingFeeRefundMaybe;
