import moment from 'moment';
import get from 'lodash/get';

export class BookingPeriod {
  constructor(start, end, pricing) {
    this._start = moment(start);
    this._end = moment(end);
    this._duration = moment.duration(this._end.diff(this._start));
    this._shortDOW = this._shortDaysOfWeek();
    this._pricing = pricing;
  }

  _numberDayOfWeekByDay(day) {
    return this._shortDOW.indexOf(day);
  }

  _countDayOfWeekInPeriod(day) {
    const d = this._start.clone();
    let count = 0;
    while (d.isSameOrBefore(this._end, 'day')) {
      if (d.day() === this._numberDayOfWeekByDay(day)) {
        count++;
      }
      d.add(1, 'd');
    }
    return count;
  }

  _shortDaysOfWeek() {
    const day = moment().startOf('week')
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(day.format('ddd').toLowerCase());
      day.add(1, 'd');
    }
    return days;
  }

  _hoursInPeriodByTypedDays(typedDays) {
    const d = this._start.clone();
    let halfHours = 0;
    while (d.isBefore(this._end)) {
      if (typedDays && typedDays.includes(this._shortDaysOfWeek()[d.day()])) {
        halfHours++;
      }
      d.add(30, 'm');
    }
    return halfHours / 2;
  }

  getRegularDays(pricing) {
    return pricing ? pricing.regularDays : [];
  }

  getPeakDays(pricing) {
    return pricing ? pricing.peakDays : [];
  }

  asHours() {
    return this._duration.asHours();
  }

  asDays() {
    return this._duration.asDays();
  }

  regularDaysCount() {
    return this.getRegularDays(this._pricing).reduce((acc, day) => acc + this._countDayOfWeekInPeriod(day), 0);
  }

  peakDaysCount() {
    return this.getPeakDays(this._pricing).reduce((acc, day) => acc + this._countDayOfWeekInPeriod(day), 0);
  }

  regularHoursCount() {
    return this._hoursInPeriodByTypedDays(this.getRegularDays(this._pricing));
  }

  peakHoursCount() {
    return this._hoursInPeriodByTypedDays(this.getPeakDays(this._pricing));
  }
}

/**
 * Get diffs in hours between booking period and update booking period.
 * @param {Date} bStart
 * @param {Date} bEnd
 * @param {Date} ubStart
 * @param {Date} ubEnd
 * @param {Object} pricing Pricing object from listing.
 * @returns {{rhDiff: number, phDiff: number}}
 */
export const getHoursDiff = (bStart, bEnd, ubStart, ubEnd, pricing) => {
  const bPeriod = new BookingPeriod(bStart, bEnd, pricing);
  const ubPeriod = new BookingPeriod(ubStart, ubEnd, pricing);
  const invalidDiff = bPeriod.asHours() > ubPeriod.asHours();
  const regularHoursDiff = ubPeriod.regularHoursCount() - bPeriod.regularHoursCount();
  const peakHoursDiff = ubPeriod.peakHoursCount() - bPeriod.peakHoursCount();
  const rhDiff = regularHoursDiff > 0 ? regularHoursDiff : 0;
  const phDiff = peakHoursDiff > 0 ? peakHoursDiff : 0;
  return {
    rhDiff,
    phDiff,
    invalidDiff,
    allHoursDiff: rhDiff + phDiff,
    bDuration: bPeriod.asHours(),
    ubDuration: ubPeriod.asHours(),
    regularHoursCount: ubPeriod.regularHoursCount(),
    peakHoursCount: ubPeriod.peakHoursCount()
  };
};

/**
 * Get update bookings from booking transaction protectedData.
 * @param {Object} bookingTx
 * @returns {*[]}
 */
export const getBookingUpdates = bookingTx => {
  return get(bookingTx, 'attributes.protectedData.bookingUpdates', []);
};

/**
 * Get update bookings transactions ids for booking.
 * @param {Object} bookingTx
 * @returns {*[]}
 */
export const getBookingUpdatesTxsIds = bookingTx => {
  return getBookingUpdates(bookingTx)
    .filter(bu => bu.txId)
    .map(bu => bu.txId)
  ;
};
