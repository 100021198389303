import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './ModalTripInstructions.css';
import  Modal  from '../../../Modal/Modal'
import get from 'lodash/get';
import { formatMoney } from '../../../../util/currency';
import { LINE_ITEM_IS_EXCESS_REDUCTION, LINE_ITEM_IS_EXCESS_REDUCTION2 } from '../../../../util/types';

const ModalTripInstructions = props => {
  const { isOpen, onClose, onManageDisableScrolling, keyRules, brandModel, fuelValue, registrationNumber, listing, transaction, intl, mileageToShowText, isDistanceCharge, distanceKmPrice, excessReductionType, learnMoreForTolls, learnMoreForExcessReduction, learnMoreForDistance } = props;
  const millagePerDay = get(listing, 'attributes.publicData.millagePerDay');
  const pickupDropoffInstructions = get(listing, 'attributes.publicData.pickupDropoffInstructions');
  const carGuide = get(listing, 'attributes.publicData.carGuide');
  const parkingInstructions = get(listing, 'attributes.publicData.parkingInstructions')
  const parkingInfo = get(listing, 'attributes.publicData.parkingInfo');
  const specificRules = get(listing, 'attributes.publicData.rules');
  const [protection, setProtection] = useState(null);
  let excessReductionLineItem = null;
  let excessReduction2LineItem = null;
  excessReductionLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_IS_EXCESS_REDUCTION && !item.reversal
  );
  const [showFullText, setShowFullText] = useState({
    specificRules: false,
    parkingInstructions: false,
    pickupInstructions: false,
    carGuide: false,
  });
  excessReduction2LineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_IS_EXCESS_REDUCTION2 && !item.reversal
  );

  const protectAddsOn = {
    'max_protection': {
      content: "Maximum Protection",
      value: excessReduction2LineItem ? formatMoney(intl, excessReduction2LineItem.lineTotal, 1) : 'N/A',
    },
    "premium_protection": {
      content: "Premium Protection",
      value: excessReductionLineItem ? formatMoney(intl, excessReductionLineItem.lineTotal, 1) : 'N/A',
    }
  };

  const getFormattedBrandName = brandModel => {
    if (!brandModel) return '-';
    return brandModel
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatTextWithLinksAndLineBreaks = text => {
    if (!text) return '-';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} style={{ color: '#0aa3ad' }} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return (
        <React.Fragment key={index}>
          {part.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    });
  };

  const toggleReadMore = section => {
    setShowFullText(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const renderWithReadMore = (text, section) => {
    const isExpanded = showFullText[section];
    const maxLength =220;
    const maxLines = 3;

    if (!text) return '-';

    // Split text into lines based on '\n'
    const lines = text.split('\n');
    const lineCount = lines.length;

    // Determine if "Read More" is needed based on length or line count
    const needsReadMore = text.length > maxLength || lineCount > maxLines;

    // Decide which text to display (truncated or full)
    let displayedText = isExpanded
      ? text
      : (text.length > maxLength ? text.slice(0, maxLength) : lines.slice(0, maxLines).join('\n'));

    // Add ellipsis only if the text length exceeds maxLength
    if (!isExpanded && text.length > maxLength) {
      displayedText += '...';
    }

    return (
      <>
        {formatTextWithLinksAndLineBreaks(displayedText)}
        {needsReadMore && (
          <button
            className={css.readMoreButton}
            onClick={() => toggleReadMore(section)}
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        )}
      </>
    );
  };

  useEffect(() => {
    if (excessReductionLineItem) {
      setProtection('premium_protection');
    } else if (excessReduction2LineItem) {
      setProtection('max_protection');
    } else {
      setProtection(null);
    }
  }, [excessReductionLineItem, excessReduction2LineItem]);


  return (
    <Modal
      isOpen={isOpen}
      containerClassName={css.modal}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
      closeButtonMessage=" "
    >
      {' '}
      <>
          <div className={`${css.modalInfoTripContainer}  ${css.displayFlex} ${css.justifyContentBetween}`}>
            <div className={`${css.tripModalContainer} ${css.instructionsWrapper}`}>
              <div className={`${css.tripBox} ${css.borderBottom}`}><h3>Key information for your trip</h3></div>
              {isDistanceCharge && distanceKmPrice && (
                <div className={`${css.tripBox} ${css.borderBottom}`}>
                  <div className={css.boxDistance}>
                    <div className={css.headingDistance}>Distance</div>
                    <div>
                      <span>Distance charged @{distanceKmPrice}/km  <a onClick={learnMoreForDistance}>Learn more</a>
                      </span>
                    </div>
                  </div>
                  {excessReductionType && (<div className={css.boxDistance}>
                    <div className={css.headingDistance}>Protection</div>
                    <div>
                      <span>{`${excessReductionType} protection`}  <a onClick={learnMoreForExcessReduction}>Learn more</a></span>
                    </div>
                  </div>)}
                  <div className={css.boxDistance}>
                    <div className={css.headingDistance}>Tolls</div>
                    <div>
                      <span>Due later  <a onClick={learnMoreForTolls}>Learn more</a></span>
                    </div>
                  </div>
                </div>
              )}
              <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>About the car</h5>
                <ol>
                  <li>Brand/Model: {getFormattedBrandName(brandModel)}</li>
                  <li>Fuel: {fuelValue}</li>
                  <li>Registration Number: {registrationNumber}</li>
              </ol>
            </div>
            <div className={`${css.tripBox} ${css.borderBottom}`}>
              <h5>Key Rules</h5>
                <ol>
                  {keyRules.map((rule, index) => <li key={index}>{rule}</li>)}
                </ol>
            </div>
              {specificRules && (<div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Instructions</h5>
                <span>{renderWithReadMore(specificRules, 'specificRules')}</span>
              </div>)
              }

              {parkingInstructions && (<div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Parking instructions</h5>
                <span>{renderWithReadMore(parkingInstructions, 'parkingInstructions')}</span>
              </div>)
              }

              {pickupDropoffInstructions && (
                <div className={`${css.tripBox} ${css.borderBottom}`}>
                  <h5>Pickup and return instructions</h5>
                  <span>{renderWithReadMore(pickupDropoffInstructions, 'pickupInstructions')}</span>
                </div>
              )}

              {carGuide && (
                <div className={`${css.tripBox} ${css.borderBottom}`}>
                  <h5>Car Guide</h5>
                  <span>{renderWithReadMore(carGuide, 'carGuide')}</span>
                </div>
              )}
            </div>
          </div>
      </>
    </Modal>
  );
};

ModalTripInstructions.defaultProps = {
  isOpen: false,
  onClose: () => {
  },
  onManageDisableScrolling: () => {
  },
};

ModalTripInstructions.prototype = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default ModalTripInstructions;
