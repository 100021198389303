// --- Actions ---
const NEXT_PICK_STEP = 'progress/NEXT_PICK_STEP';
const PREVIOUS_PICK_STEP = 'progress/PREVIOUS_PICK_STEP';
const NEXT_DROP_STEP = 'progress/NEXT_DROP_STEP';
const PREVIOUS_DROP_STEP = 'progress/PREVIOUS_DROP_STEP';
const SET_VERIFICATION_STATUS = 'progress/SET_VERIFICATION_STATUS';
const RESET_STEPS = 'progress/RESET_STEPS';
const COMPLETED_STEP = 'progress/COMPLETED_STEPS';
const IS_PROGRESS_BAR_ENABLED = 'progress/IS_PROGRESS_BAR_ENABLED';
// Action Types
const SET_PICK_TOTAL_STEPS = 'progress/SET_PICK_TOTAL_STEPS';
const SET_DROP_TOTAL_STEPS = 'progress/SET_DROP_TOTAL_STEPS';

const SET_PICK_UP_STEP = 'progress/SET_PICK_UP_STEP';


// Action Creators
export const setPickTotalSteps = (totalSteps) => ({
  type: SET_PICK_TOTAL_STEPS,
  totalSteps
});

export const setDropTotalSteps = (totalSteps) => ({
  type: SET_DROP_TOTAL_STEPS,
  totalSteps
});
export const nextPickStep = () => ({ type: NEXT_PICK_STEP });
export const previousPickStep = () => ({ type: PREVIOUS_PICK_STEP });
export const nextDropStep = () => ({ type: NEXT_DROP_STEP });
export const previousDropStep = () => ({ type: PREVIOUS_DROP_STEP });
export const setVerificationStatus = (status) => ({ type: SET_VERIFICATION_STATUS, status });
export const resetSteps = () => ({ type: RESET_STEPS });
export const enableProgressBar = (step) =>  ({ type: IS_PROGRESS_BAR_ENABLED, step });

export const setPickUpStep = (step) => ({
  type: SET_PICK_UP_STEP,
  step
});


// --- Reducer ---
const initialState = {
  pickStep: 1,
  dropStep: 1,
  verificationStatus: null , // 'clear', 'pending', 'reject'
  pickTotalSteps: 5,
  dropTotalSteps: 3,
  isProgressBarEnabled: true,
};

const reducer = (state = initialState, action) => {
  console.log("action", "action", action)
  switch (action.type) {
    case NEXT_PICK_STEP:
      return {
        ...state,
        pickStep: Math.min(state.pickStep + 1, state.pickTotalSteps + 1),
      };
    case PREVIOUS_PICK_STEP:
      return {
        ...state,
        pickStep: Math.max(state.pickStep - 1, 1)
      };
    case NEXT_DROP_STEP:
      return {
        ...state,
        dropStep: Math.min(state.dropStep + 1, state.dropTotalSteps +1 )
      };
    case PREVIOUS_DROP_STEP:
      return {
        ...state,
        dropStep: Math.max(state.dropStep - 1, 1)
      };
      case SET_VERIFICATION_STATUS:
        return {
          ...state,
          verificationStatus: action.status,
        };
    case RESET_STEPS:
      return {
        ...state,
        pickStep: 1,
        dropStep: 1,
        // verificationStatus: ,
        isProgressBarEnabled: true,
      };
    case IS_PROGRESS_BAR_ENABLED:
      return {
        ...state,
        isProgressBarEnabled:  action.step
      };
    case SET_PICK_TOTAL_STEPS:
      return {
        ...state,
        pickTotalSteps: action.totalSteps,
      };
    case SET_DROP_TOTAL_STEPS:
      return {
        ...state,
        dropTotalSteps: action.totalSteps,
      };
    case SET_PICK_UP_STEP:
      return {
        ...state,
        pickStep: action.step,
      };
    default:
      return state;
  }
};

export default reducer;
